import React, { useState, useEffect } from 'react';
import supabase from './SupabaseClient';

const Footer = () => {
    const [session, setSession] = useState(null);

    useEffect(() => {
        const { data: { subscription } } = supabase.auth.onAuthStateChange(
            async (event, session) => {
                setSession(session);
            }
        );
    
        return () => {
            subscription?.unsubscribe();
        };
      }, []);
    
    return (
        <footer className='mainFooter redBG'>
            <div>
                <img className='footerLogo' src="Thrill_Price_Tracker_Footer_Logo.svg" alt="Thrill Price Tracker Footer Logo"/> 
                <p className='smallP noMarginP lightText'>© 2024 Thrill - Price Tracker</p>
            </div>
            <div className="footerLinks">
                {session ? (  
                    <div>                    
                        <a href="/support" target="_blank">Support</a><br/>
                    </div>
                ) : (  
                    <div>                    
                        <a href="/plans" target="_blank">Pricing</a><br/>
                    </div>
                )}
                <div>
                    <a href="/stores" target="_blank">Available Stores</a><br/>
                </div>
                <div>
                    <a href="/terms" target="_blank">Terms of Use</a><br/>
                </div>
                <div>
                    <a href="/privacy" target="_blank">Privacy Policy</a><br/>
                </div>
            </div>
        </footer>
    );
}

export default Footer;