import React from 'react';

const LogoBig = () => {
  const handleLogoClick = () => {
    window.location.href = '/'; 
    window.location.reload(); 
  };

  return (
    <a href="/" onClick={handleLogoClick}>      
      <svg width="160" height="60" viewBox="0 0 160 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.66464 37.5763V6.16994H0V0H19.6405V6.16994H14.0223V37.5763H5.66464Z" fill="black"/>
        <path d="M23.0909 37.5763V0H31.4022V15.2161H37.2061V0H45.5638V37.5763H37.2061V21.1541H31.4022V37.5763H23.0909Z" fill="black"/>
        <path d="M51.2912 37.5763V0H61.6454C64.1837 0 66.3814 0.278343 68.2387 0.83503C70.0959 1.39172 71.5353 2.39685 72.5568 3.85041C73.6092 5.27306 74.1355 7.29878 74.1355 9.92758C74.1355 11.4739 74.0116 12.8502 73.764 14.0563C73.5164 15.2625 73.0521 16.314 72.3711 17.2109C71.721 18.0768 70.7769 18.8036 69.5387 19.3912L74.6926 37.5763H66.1028L61.9704 20.7366H59.6024V37.5763H51.2912ZM59.6024 15.9583H61.924C63.1002 15.9583 64.0134 15.7573 64.6634 15.3553C65.3135 14.9223 65.7623 14.3192 66.01 13.546C66.2885 12.7419 66.4278 11.7832 66.4278 10.6698C66.4278 9.06162 66.1338 7.80908 65.5456 6.91219C64.9575 6.01531 63.8741 5.56686 62.2954 5.56686H59.6024V15.9583Z" fill="black"/>
        <path d="M121.511 37.5763V0H129.822V31.9631H138.458V37.5763H121.511Z" fill="black"/>
        <path d="M142.097 37.5763V0H150.408V31.9631H159.044V37.5763H142.097Z" fill="black"/>
        <path d="M102.204 44.1776L109.896 36.4855L115.773 42.3624L98.0963 59.9959L98.0971 59.9467L98.0963 59.9967L80.417 42.3665L86.2939 36.4896L93.8789 44.0745V22.4922H102.204V44.1776Z" fill="#FF3D00"/>
      </svg>
    </a>
  );
}; 

export default LogoBig;