import React from 'react';

const Next = () => {
  return (
    <svg width="109" height="50" viewBox="0 0 109 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M31.9189 37.3436H50.6729V33.5142H35.8684V26.8124H49.3245V23.0172H35.8684V16.4453H50.6732V12.6152H31.9189V37.3436ZM86.7488 16.4411H95.8071V37.3395H99.7566V16.4411H108.815V12.6116H86.7488V16.4411ZM18.3309 28.7687L0 11.875V37.3436H3.94984V21.021L22.2804 38.125V12.6145H18.3309V28.7687ZM76.9344 12.6133L69.6863 21.6249L62.4378 12.6133H57.3316L67.1563 24.6799L57.3316 37.343H62.4378L69.6859 27.9445L76.9344 37.3443H82.0408L72.2159 24.6811L82.0408 12.6142L76.9344 12.6133Z" fill="black"/>
    </svg>
    );
}; 

export default Next;