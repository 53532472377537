import React from 'react';

const Shopbop = () => {
  return (
    <svg width="121" height="50" viewBox="0 0 121 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M78.0911 17.889C76.1062 17.889 74.1217 19.0759 73.0445 20.1829V10H72.5557C71.4187 11.0372 68.8056 12.4334 66.9707 12.8425V13.3313C66.9707 13.3313 68.6064 13.9495 68.6064 16.3633C68.6064 16.3633 68.6064 24.4517 68.6064 26.6857C68.6064 31.7921 71.9675 34.0263 75.8671 34.0263C81.4822 34.0263 84.095 29.9572 84.095 25.3595C84.1049 20.871 82.1701 17.889 78.0911 17.889ZM76.1461 32.9589C74.2012 32.9589 73.0442 31.1041 73.0442 27.6631V21.6889C73.4531 21.1106 74.5003 20.3027 75.737 20.3027C78.0007 20.3027 79.3175 22.6166 79.3473 26.8852C79.3376 30.8047 78.2006 32.9589 76.1461 32.9589Z" fill="black"/>
        <path d="M3.69024 20.6816C3.69024 19.6044 4.73746 18.9461 6.08383 18.9461C8.15834 18.9461 9.70421 20.4622 10.3325 22.7062H10.8213L12.1677 18.9862C11.4496 18.6071 9.27521 17.7793 6.76205 17.7793C3.27131 17.7793 0.27943 19.6344 0.27943 22.776C0.27943 28.1118 9.17578 27.6631 9.17578 31.064C9.17578 32.191 8.0887 32.8493 6.90176 32.8493C4.40853 32.8493 2.48359 30.5255 1.77537 28.5209H1.28658L0 32.6898C1.04722 33.338 3.6701 34.0362 6.05383 34.0362C9.83385 34.0362 12.8857 32.1211 12.8857 28.7999C12.8857 23.4941 3.69024 23.9728 3.69024 20.6816Z" fill="black"/>
        <path d="M29.6315 28.8598V21.978C29.6315 19.4048 27.6766 17.7692 25.0138 17.7692C22.7199 17.7692 20.9049 19.0656 20.1167 20.2226V10H19.6281C18.4911 11.0371 15.8781 12.4334 14.043 12.8425V13.3313C14.043 13.3313 15.6587 13.9495 15.6587 16.3633C15.6587 18.0686 15.6587 28.85 15.6587 28.85C15.6587 32.0116 15.0004 32.4406 14.2425 32.9793V33.4679H21.5332V32.9793C20.775 32.4406 20.1169 32.0217 20.1169 28.85V21.6491C20.5858 20.8112 22.0517 20.0334 23.1688 20.0334C24.5951 20.0334 25.1835 20.9709 25.1835 22.2675V28.8399C25.1835 32.0015 24.5253 32.4303 23.7673 32.969V33.4578H31.0578V32.969C30.2797 32.4504 29.6315 32.0214 29.6315 28.8598Z" fill="black"/>
        <path d="M60.6792 17.8379C58.6446 17.8379 56.3905 19.2143 55.2537 20.2216V17.8477H54.7649C53.6279 18.8849 51.1944 20.2812 49.3691 20.6903V21.1791C49.3691 21.1791 51.0048 21.7975 51.0048 24.211L50.9947 35.3814C50.9947 38.543 50.3364 38.9718 49.5785 39.5103V39.9988H56.869V39.51C56.111 38.9715 55.4528 38.5528 55.4528 35.3812C55.4528 35.2614 55.4528 34.8028 55.4528 33.2966C56.0611 33.6257 57.248 34.0346 58.7541 34.0346C63.9801 34.0346 66.4335 29.8157 66.4335 25.2177C66.424 20.7201 64.1899 17.8379 60.6792 17.8379ZM58.4551 32.9576C56.8793 32.9576 55.433 31.5116 55.433 28.0607V21.6477C55.6325 21.1788 56.8394 20.2913 58.0362 20.2913C60.37 20.2913 61.6765 22.6051 61.6765 26.8737C61.6765 30.9529 60.1506 32.9576 58.4551 32.9576Z" fill="black"/>
        <path d="M114.656 17.8379C112.621 17.8379 110.367 19.2143 109.23 20.2216V17.8477H108.741C107.604 18.8849 105.171 20.2812 103.346 20.6903V21.1791C103.346 21.1791 104.981 21.7975 104.981 24.211L104.972 35.3814C104.972 38.543 104.313 38.9718 103.555 39.5103V39.9988H110.846V39.51C110.088 38.9715 109.43 38.5528 109.43 35.3812C109.43 35.2614 109.43 34.8028 109.43 33.2966C110.038 33.6257 111.225 34.0346 112.731 34.0346C117.957 34.0346 120.41 29.8157 120.41 25.2177C120.4 20.7201 118.166 17.8379 114.656 17.8379ZM112.442 32.9576C110.866 32.9576 109.419 31.5116 109.419 28.0607V21.6477C109.619 21.1788 110.826 20.2913 112.022 20.2913C114.356 20.2913 115.663 22.6051 115.663 26.8737C115.653 30.9529 114.127 32.9576 112.442 32.9576Z" fill="black"/>
        <path d="M94.5792 17.7793C89.2033 17.7793 85.9121 21.4993 85.9121 25.8078C85.9121 30.1165 88.964 34.0458 94.4095 34.0458C98.4188 34.0458 102.209 31.2134 102.209 25.9074C102.209 21.1205 98.6483 17.7793 94.5792 17.7793ZM95.7858 32.5002C92.3849 32.5002 90.1908 26.8651 90.1908 22.9155C90.1908 20.8112 90.869 19.1955 92.5248 19.1955C95.3172 19.1955 98.0202 24.641 98.0202 29.3984C98.02 31.373 97.1922 32.5002 95.7858 32.5002Z" fill="black"/>
        <path d="M40.6512 17.7793C35.2756 17.7793 31.9844 21.4993 31.9844 25.8078C31.9844 30.1165 35.0363 34.0458 40.4817 34.0458C44.491 34.0458 48.2809 31.2134 48.2809 25.9074C48.2809 21.1205 44.7205 17.7793 40.6512 17.7793ZM41.8581 32.5002C38.4571 32.5002 36.263 26.8651 36.263 22.9155C36.263 20.8112 36.9413 19.1955 38.5968 19.1955C41.3894 19.1955 44.0922 24.641 44.0922 29.3984C44.0922 31.373 43.2642 32.5002 41.8581 32.5002Z" fill="black"/>
    </svg>
    );
}; 

export default Shopbop;