import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';  
import UserData from './UserData';
import supabase from './SupabaseClient';

const PaymentAccepted = () => {
    const [session, setSession] = useState(null);
    const [userData, setUserData] = useState(null);
    const [productData, setProductDataState] = useState(null);
    const { productId } = useParams();

    useEffect(() => {
        const { data: { subscription } } = supabase.auth.onAuthStateChange(
          async (event, session) => {
            setSession(session);
          }
        );

        return () => {
          subscription?.unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (productId) {
            const fetchProductData = async () => {
                const response = await fetch(`/getProduct?id=${productId}`);
                const data = await response.json();

                if (data.product) {
                    setProductDataState(data.product);
                } else {
                    console.log("no product found");
                }
            };
            fetchProductData();
        }
    }, [productId]);

    const handleConfirm = useCallback(async () => {
        try {
            const payload = { productId, userID: session.user.id, priceText: productData.productPrice };
            const response = await fetch('/confirmProduct', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });
            console.log(response);
        } catch (error) {
            console.error('Error confirming product:', error);
        }
    }, [productId, session, productData]);

    useEffect(() => {
        if (productId && productData) {
            handleConfirm();
        }
    }, [productId, productData, handleConfirm]);

    return (
        <div>
            {session && <UserData userID={session.user.id} setUserData={setUserData} />}
            {userData && <p>Trackers: {userData.trackers}</p>}
            {productId && productData ? (
                <>
                    <p>Title: {productData.productTitle}</p>
                    <p>Price: {productData.productPrice}</p>
                    <p>Category: {productData.productCategory}</p>
                    <img src={productData.productImg} alt="Product" />
                    <p>Your payment for {productData.productTitle} was accepted.</p>
                </>
            ) : (
                <p>Your payment was accepted. Thank you.</p>
            )}
            <a href="/">Go to Dashboard</a>
        </div>
    );
};

export default PaymentAccepted;
