import React, { useState } from 'react';
import './DeleteProduct.css';

const DeleteProduct = ({ productID, productTitle, userID, refreshProducts }) => {
    const [showPopup, setShowPopup] = useState(false);

    const handleDelete = async () => {
        try {
            const response = await fetch(`/delete?id=${productID}&userID=${userID}`, {
              method: 'DELETE'
            });
            if (response.ok) {
                refreshProducts();  
                setShowPopup(false);  
            } else {
                console.error('Error deleting product:', response.statusText);
            }
          } catch (error) {
            console.error('Error deleting product:', error);
          }
    };

    return (
        <>
            <div className='pntr deleteElement' onClick={() => setShowPopup(true)}>
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7929 28.0015C11.4024 27.611 11.4024 26.9778 11.7929 26.5873L26.3802 11.9999C26.7708 11.6094 27.4039 11.6094 27.7945 11.9999C28.185 12.3904 28.185 13.0236 27.7945 13.4141L13.2071 28.0015C12.8166 28.392 12.1834 28.392 11.7929 28.0015Z" fill="#D6D6D6"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M28.2071 28.0015C27.8166 28.392 27.1834 28.392 26.7929 28.0015L12.2055 13.4141C11.815 13.0236 11.815 12.3904 12.2055 11.9999C12.5961 11.6094 13.2292 11.6094 13.6198 11.9999L28.2071 26.5873C28.5976 26.9778 28.5976 27.611 28.2071 28.0015Z" fill="#D6D6D6"/>
                </svg>
            </div>
            {showPopup && (
                <div className="popup-overlay">
                    <div className="popup">
                        <p className='noMarginP'>Delete to remove from your list and stop price notifications.</p>
                        <div className="popup-buttons">
                            <button className='btnBasic greyBtn' onClick={() => setShowPopup(false)}>Cancel</button>
                            <button className='btnBasic' onClick={handleDelete}>Delete</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DeleteProduct;
