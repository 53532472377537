// SignIn.js
import React, { useState } from 'react';
import supabase from './SupabaseClient';
import LogoBig from './icons/LogoBig';

function SignIn({ language }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [error, setError] = useState(null);
  const [resetSent, setResetSent] = useState(false);

  const handleSignIn = async (e) => {
    e.preventDefault();
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (error) {
      setErrorMessage(error.message);
    }
  };

  const handleResetPassword = async () => {
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(resetEmail, {
        redirectTo: `${window.location.origin}/updatepassword`,
      });

      if (error) {
        setError(error.message);
      } else {
        setResetSent(true);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const texts = {
    en: {
      emailLabel: 'Your Email',
      passwordLabel: 'Your Password',
      forgotPassword: 'You forgot your password?',
      resetPassword: 'Forgot Password?',
      signIn: 'Continue',
      notUser: 'Not a Member Yet?',
      registerNow: 'Register Now!',
      signUp: 'Sign up',
    },
    fr: {
      emailLabel: 'Votre Email',
      passwordLabel: 'Votre Mot de passe',
      forgotPassword: 'Vous avez oublié votre mot de passe ?',
      resetPassword: 'Réinitialiser votre mot de passe ?',
      signIn: 'Continuer',
      notUser: 'Ou Inscrivez-vous Maintenant',
      registerNow: 'Inscrivez-vous !',
      signUp: 'S\'inscrire',
    }
  };

  const t = texts[language];

  return (
    <div className='onbrdFlex'>
        <p>{language}</p>
      <div className='formLeft'>
        <div className='marginB'>
          <LogoBig />
        </div>
        <form className='formElements' onSubmit={handleSignIn}>
        {errorMessage && <p className='noMarginP redText centeredText'>{errorMessage}</p>}
          <input className='mainInput inputSmall' type="email" id="emailInput" value={email} onChange={(e) => setEmail(e.target.value)} placeholder={t.emailLabel} />
          <input className='mainInput inputSmall' type="password" id="passwordInput" value={password} onChange={(e) => setPassword(e.target.value)} placeholder={t.passwordLabel} />
          <p className='baseLink smallP noMarginP rAligned pntr' onClick={() => setShowPopup(true)}><strong>{t.resetPassword}</strong></p>
          <button className='btnBasic' type="submit">{t.signIn}</button>
          <p className='centeredText'>{t.notUser} <a className='redLink' href="/signup"><strong>{t.registerNow}</strong></a></p>
        </form>
      </div>
      <div className='formRight'>
        <h1 className='capText'>Track Prices, Buy at the Lowest, Save Money!</h1>
        <p className='noMarginP whiteText'>Get notified when the price drops on your favorite items.</p>
        <ul>
          <li className='marginS'>📈 Real-time price tracking</li>
          <li className='marginS'>⏱️ Instant alerts on price drops</li>
          <li className='marginS'>😏 Track multiple items at once</li>
        </ul>
      </div>
      {showPopup && (
        <div className="popup-overlay">
            <div className="popup">
                {!resetSent && (
                  <div className='formElements'>
                    <h3 className='noMarginP'>Forgot Password?</h3>
                    <p className='noMarginP'>No worries! Fill in your email and we'll send you a link to reset your password</p>
                    <input className='mainInput inputSmall' type="email" id="resetEmailInput" value={resetEmail} onChange={(e) => setResetEmail(e.target.value)} placeholder={t.emailLabel}/>
                    {error && <div style={{ color: 'red' }}>{error}</div>}
                    <button className='btnBasic' onClick={handleResetPassword}>Reset Password</button>
                    <button className='btnBasic greyBtn' onClick={() => setShowPopup(false)}>Cancel</button>
                 </div>
                )}
                {resetSent && 
                  <div className='formElements'>
                    <p className='noMarginP bigP'>
                      We've sent a secure link to reset your password to <strong>{resetEmail}.</strong> 
                    </p>
                    <p className='noMarginP'>
                      Please check your inbox and follow the instructions to regain access to your account. If you don't see the email, be sure to check your spam or junk folder.
                    </p>
                    <button className='btnBasic' onClick={() => setShowPopup(false)}>Close this window</button>
                  </div>
                }
            </div>
        </div>
      )}
    </div>
  );
}

export default SignIn;
