import React from 'react';

const Walmart = () => {
  return (
    <svg width="157" height="50" viewBox="0 0 157 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M90.7112 26.2079C90.7112 22.5045 89.1259 19.2324 83.7644 19.2324C81.0137 19.2324 78.8286 20.0164 77.6439 20.7027L78.5122 23.6738C79.603 22.9895 81.3434 22.4259 82.9861 22.4259C85.7005 22.4221 86.1452 23.9575 86.1452 24.9447V25.1824C80.2201 25.1728 76.4746 27.22 76.4746 31.3892C76.4746 33.9367 78.3761 36.3251 81.7 36.3251C83.728 36.3251 85.4417 35.5066 86.4653 34.2146H86.5649C86.5649 34.2146 87.2398 37.0382 90.9718 35.959C90.7821 34.7763 90.7112 33.5303 90.7112 32.016V26.2079ZM86.2909 30.1183C86.2909 30.4173 86.266 30.7279 86.1778 30.9943C85.8365 32.1348 84.6597 33.0894 83.1951 33.0894C81.9588 33.0894 80.9888 32.3898 80.9888 30.9234C80.9888 28.6806 83.4654 28.0634 86.2909 28.0787" fill="#0071CE"/>
        <path d="M0 14.1934C0 14.1934 3.87011 30.0267 4.48545 32.5914C5.21001 35.5894 6.50773 36.6973 10.2533 35.9594L12.6724 26.1412C13.2839 23.7087 13.6998 21.9643 14.0947 19.4858H14.1617C14.4359 21.9892 14.8308 23.7087 15.3311 26.1488C15.3311 26.1488 16.3182 30.6094 16.8301 32.946C17.3284 35.2827 18.7315 36.7568 22.4062 35.9594L28.1645 14.1934H23.516L21.5398 23.609C21.0165 26.3367 20.5411 28.4855 20.1711 30.9851H20.104C19.7705 28.5123 19.3469 26.4517 18.8025 23.7796L16.7553 14.1934H11.9056L9.72039 23.5342C9.09738 26.3865 8.51468 28.6734 8.14854 31.0944H8.08531C7.7077 28.8229 7.1959 25.9303 6.65339 23.1854C6.65339 23.1854 5.35567 16.4974 4.89757 14.1934" fill="#0071CE"/>
        <path d="M41.1193 26.2079C41.1193 22.5045 39.5301 19.2324 34.1686 19.2324C31.4257 19.2324 29.2366 20.0164 28.052 20.7027L28.9203 23.6738C30.0072 22.9895 31.7458 22.4259 33.3943 22.4259C36.1086 22.4221 36.5533 23.9575 36.5533 24.9447V25.1824C30.6283 25.1728 26.8789 27.22 26.8789 31.3892C26.8789 33.9367 28.7881 36.3251 32.0966 36.3251C34.1437 36.3251 35.8422 35.5066 36.8658 34.2146H36.9692C36.9692 34.2146 37.6478 37.0382 41.3761 35.959C41.1844 34.7763 41.1193 33.5303 41.1193 32.016V26.2079ZM36.6952 30.1183C36.6952 30.4173 36.6703 30.7279 36.5859 30.9943C36.2408 32.1348 35.0639 33.0894 33.5956 33.0894C32.3668 33.0894 31.3931 32.3898 31.3931 30.9234C31.3931 28.6806 33.8696 28.0634 36.6952 28.0787" fill="#0071CE"/>
        <path d="M43.5312 14.1934V35.9517H47.9553V31.3378V14.1934H43.5312Z" fill="#0071CE"/>
        <path d="M104.291 14.1934V30.2471C104.291 32.463 104.707 34.008 105.602 34.9549C106.388 35.7849 107.674 36.3255 109.206 36.3255C110.521 36.3255 111.807 36.0724 112.415 35.852L112.361 32.3959C111.907 32.5013 111.387 32.5914 110.674 32.5914C109.164 32.5914 108.665 31.6349 108.665 29.6452V23.4997H112.518V19.3421H108.665V14.1934" fill="#0071CE"/>
        <path d="M92.8555 19.6083V35.9573H97.4291V27.5844C97.4291 27.134 97.4577 26.7448 97.5288 26.3845C97.8699 24.619 99.2098 23.5015 101.14 23.5015C101.679 23.5015 102.045 23.5609 102.451 23.6242V19.3381C102.11 19.2767 101.878 19.2441 101.458 19.2441C99.7465 19.2441 97.8066 20.331 96.9881 22.6983H96.8751V19.6083" fill="#0071CE"/>
        <path d="M50.5234 19.6083V35.9573H54.9801V26.3615C54.9801 25.911 55.0299 25.4509 55.189 25.035C55.5513 24.0631 56.4503 22.9379 57.8937 22.9379C59.6879 22.9379 60.5351 24.4523 60.5351 26.6375V35.9573H64.9804V26.2464C64.9804 25.8228 65.0398 25.3033 65.1643 24.9276C65.5209 23.8254 66.4947 22.9379 67.8404 22.9379C69.6537 22.9379 70.5316 24.4197 70.5316 26.9959V35.9573H74.9729V26.3174C74.9729 21.253 72.3967 19.2441 69.4715 19.2441C68.1854 19.2441 67.1618 19.5604 66.2416 20.1278C65.4634 20.6051 64.781 21.2779 64.1695 22.1578H64.1025C63.399 20.3981 61.7466 19.2441 59.5882 19.2441C56.8203 19.2441 55.5628 20.6415 54.8229 21.8223H54.7558V19.6083" fill="#0071CE"/>
        <path d="M136.453 18.8745C137.327 18.8745 138.063 18.4183 138.159 17.8317L139.026 8.01735C139.026 7.0455 137.881 6.25 136.453 6.25C135.018 6.25 133.881 7.0455 133.881 8.01735L134.755 17.8317C134.847 18.4183 135.579 18.8745 136.453 18.8745Z" fill="#FFC220"/>
        <path d="M131.138 21.9445C131.571 21.1778 131.546 20.3267 131.079 19.9472L122.989 14.2905C122.148 13.7998 120.888 14.3844 120.168 15.6227C119.449 16.8591 119.595 18.2469 120.419 18.7281L129.374 22.88C129.948 23.0947 130.697 22.696 131.138 21.9369" fill="#FFC220"/>
        <path d="M141.767 21.9379C142.225 22.697 142.974 23.0957 143.532 22.8925L152.488 18.7291C153.32 18.2384 153.444 16.864 152.741 15.6276C152.022 14.3893 150.755 13.8085 149.915 14.2896L141.824 19.9405C141.375 20.3258 141.343 21.1807 141.776 21.9456" fill="#FFC220"/>
        <path d="M136.453 31.1328C137.327 31.1328 138.063 31.589 138.159 32.1756L139.026 41.9899C139.026 42.9618 137.881 43.7496 136.453 43.7496C135.018 43.7496 133.881 42.9618 133.881 41.9899L134.755 32.1756C134.847 31.589 135.579 31.1328 136.453 31.1328Z" fill="#FFC220"/>
        <path d="M141.767 28.0711C142.225 27.3101 142.974 26.9037 143.532 27.1242L152.488 31.2799C153.32 31.7611 153.444 33.147 152.741 34.3852C152.022 35.614 150.755 36.1986 149.915 35.7175L141.824 30.0589C141.375 29.6774 141.343 28.8263 141.776 28.0711" fill="#FFC220"/>
        <path d="M131.138 28.0629C131.571 28.8278 131.546 29.6769 131.079 30.0603L122.989 35.7093C122.148 36.2 120.888 35.6135 120.168 34.3848C119.449 33.1484 119.595 31.7606 120.419 31.2794L129.374 27.1237C129.948 26.9051 130.697 27.3077 131.138 28.0629Z" fill="#FFC220"/>
        <path d="M155.109 34.8556H155.22C155.371 34.8556 155.433 34.9035 155.45 35.0243C155.483 35.1585 155.5 35.2486 155.532 35.2869H155.82C155.795 35.2582 155.762 35.1853 155.745 35.0166C155.713 34.871 155.638 34.7885 155.532 34.7598V34.7329C155.678 34.7004 155.762 34.6026 155.762 34.4818C155.762 34.3726 155.713 34.294 155.663 34.2441C155.567 34.1981 155.475 34.1617 155.272 34.1617C155.092 34.1617 154.959 34.1771 154.854 34.202V35.2869H155.109V34.8556ZM155.117 34.3611L155.247 34.3438C155.4 34.3438 155.483 34.4147 155.483 34.5144C155.483 34.6256 155.371 34.6774 155.23 34.6774H155.117V34.3611ZM155.279 33.625C154.651 33.625 154.152 34.1157 154.152 34.7176C154.152 35.3348 154.651 35.8256 155.279 35.8256C155.916 35.8256 156.399 35.3348 156.399 34.7176C156.399 34.1157 155.916 33.625 155.279 33.625ZM155.279 33.8454C155.762 33.8454 156.121 34.2365 156.121 34.7176C156.121 35.2026 155.762 35.6051 155.279 35.5975C154.796 35.5975 154.43 35.2026 154.43 34.7176C154.43 34.2365 154.796 33.8454 155.272 33.8454" fill="#FFC220"/>
    </svg>
    );
}; 

export default Walmart;