import React, { useState } from 'react';
import { useParams } from 'react-router-dom';  
import supabase from './SupabaseClient';
import LogoBig from './icons/LogoBig';

function SignUp() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [message, setMessage] = useState('');
  const { productId } = useParams();
  const [signedUp, setSignedUp] = useState(false);


  const handleSignUp = async (e) => {
    e.preventDefault();
    if (password !== passwordConfirmation) {
      setMessage('Passwords do not match');
      return;
    }

    // Conditionally set emailRedirectTo based on presence of productId
    const emailRedirectTo = productId
      ? `${window.location.origin}/confirmedemail/${productId}`
      : `${window.location.origin}/confirmedemail`;
      console.log(emailRedirectTo);

    const { error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        emailRedirectTo: emailRedirectTo  
      }
    });
    
    if (error) {
      setMessage(error.message);
    } else {
      setSignedUp(true);
    }
  };
  return (
    <div className='onbrdFlex'>
      <div className={signedUp ? 'formLeft fullWidth' : 'formLeft'}>
        <div className='marginB'>
          <LogoBig />
        </div>
        {signedUp && <p className='noMarginP'>Almost done! Please check your inbox to verify your email address.</p>}
        {!signedUp && <div className='formElements'>  
          <form className='formElements' onSubmit={handleSignUp}>
            {message && <p className='noMarginP redText centeredText'>{message}</p>} 
            <input className='mainInput inputSmall' type="email" id="emailInput" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
            <input className='mainInput inputSmall' type="password" id="passwordInput" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
            {password && <div className='formElements' id='confirmPWinput'>
              <input className='mainInput inputSmall' type="password" id="confirmPassword" value={passwordConfirmation} onChange={(e) => setPasswordConfirmation(e.target.value)} placeholder="Confirm Password" />
            </div>}
            <button className='btnBasic' type="submit">Continue</button>
          </form>
          <p className='centeredText noMarginP'>Already a user? <a className='redLink' href="/signin"><strong>Sign In!</strong></a></p>
        </div>}
      </div>
      {!signedUp && <div className='formRight'>
        <h1 className='capText'>Track Prices, Buy at the Lowest, Save Money!</h1>
        <p className='noMarginP whiteText'>Get notified when the price drops on your favorite items.</p>
        <ul>
          <li className='marginS'>📈 Real-time price tracking</li>
          <li className='marginS'>⏱️ Instant alerts on price drops</li>
          <li className='marginS'>😏 Track multiple items at once</li>
        </ul>
      </div>}
    </div>
  );
}

export default SignUp;