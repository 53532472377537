import React from 'react';

const Oldnavy = () => {
  return (
    <svg width="104" height="50" viewBox="0 0 104 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M51.9586 41.875C80.6545 41.875 103.917 34.3198 103.917 25C103.917 15.6802 80.6545 8.125 51.9586 8.125C23.2627 8.125 0 15.6802 0 25C0 34.3198 23.2627 41.875 51.9586 41.875Z" fill="white"/>
        <path d="M63.3621 26.541H66.1061L64.7197 21.3841L63.3621 26.541ZM37.4878 21.2726H36.791V28.7705H37.4794C37.8741 28.8264 38.2766 28.769 38.6399 28.6048C39.0033 28.4407 39.3124 28.1766 39.5313 27.8434C39.9747 26.9658 40.2057 25.9964 40.2057 25.0132C40.2057 24.0299 39.9747 23.0605 39.5313 22.1829C39.3125 21.8526 39.004 21.5916 38.642 21.4303C38.2801 21.2691 37.8797 21.2144 37.4878 21.2726ZM18.2454 21.0352C16.3074 21.0352 15.9668 23.7239 15.9668 25.0611C15.9668 26.3983 16.558 28.9252 18.2454 28.9264C19.9328 28.9276 20.53 26.3839 20.5312 25.0623C20.5324 23.7407 20.1834 21.0376 18.2454 21.0352Z" fill="#003874"/>
        <path d="M51.9582 8.125C23.2643 8.125 0.00195312 15.6804 0.00195312 24.9976C0.00195312 34.3148 23.2679 41.8738 51.9582 41.8738C80.6484 41.8738 103.918 34.3184 103.918 24.9976C103.918 15.6768 80.6496 8.125 51.9582 8.125ZM18.2369 31.3118C15.0265 31.3118 13.0201 28.659 13.0129 25.0588V25.0312C13.0129 21.2367 15.0085 18.687 18.2465 18.6882C21.4846 18.6894 23.4742 21.2451 23.4718 25.0444C23.4634 28.6494 21.457 31.3118 18.2369 31.3118ZM32.2685 31.1511H25.0248V18.9017H27.8263V28.7681H32.2697L32.2685 31.1511ZM43.2178 25.0168V25.0959C43.2835 26.8 42.7339 28.4708 41.6696 29.8031C40.5231 31.1223 39.295 31.1523 37.7575 31.1511H33.8395V18.9017H37.7659C39.3034 18.9017 40.5315 18.9077 41.6768 20.2209C42.7436 21.5618 43.2912 23.2424 43.219 24.9544L43.2178 25.0168ZM57.7123 31.1511H54.8988L51.0035 24.1425V31.1487H48.1828V18.9017H51.0011L54.906 26.0026V18.9017H57.7171L57.7123 31.1511ZM67.3413 31.1511L66.7008 28.7681H62.7696L62.1424 31.1511H59.2821L62.5741 18.9017H66.8507L70.1476 31.1511H67.3413ZM76.3658 31.1511H72.6349L69.4892 18.9017H72.5977L74.5033 27.4429L76.4222 18.9017H79.5271L76.3658 31.1511ZM87.2384 27.245V31.1511H84.1119V27.2438L80.4529 18.9017H83.5387L85.677 24.2565L87.8237 18.9017H90.9082L87.2384 27.245Z" fill="#003764"/>
    </svg>
    );
}; 

export default Oldnavy;