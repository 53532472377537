import React from 'react';
import './Terms.css'; 
import Footer from './Footer';
import NavBar from './NavBar';

const termsSections = [
  {
    title: '1. Acceptance of Terms',
    content: `By accessing or using the Thrill service, website, or applications (collectively, "Service"), you agree to comply with and be bound by these Terms of Use ("Terms"). If you do not agree to these Terms, please do not use the Service.`,
  },
  {
    title: '2. Description of Service',
    content: `Thrill is a price-tracking service that monitors the prices of online products based on the URLs you provide. You will receive notifications via email when there are changes in the prices of your tracked items. The Service is provided on an "as-is" and "as-available" basis.`,
  },
  {
    title: '3. Eligibility',
    content: `You must be at least 12 years old or the age of majority in your jurisdiction to use this Service. By using Thrill, you represent and warrant that you meet this eligibility requirement.`,
  },
  {
    title: '4. Account Registration',
    content: `To use certain features of the Service, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update this information as necessary. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.`,
  },
  {
    title: '5. User Responsibilities',
    content: `You agree not to:
    - Use the Service for any illegal or unauthorized purpose.
    - Interfere with or disrupt the Service or servers or networks connected to the Service.
    - Attempt to access any other user's account or personal information.`,
  },
  {
    title: '6. Pricing Information and Accuracy',
    content: `Thrill makes reasonable efforts to monitor the prices of items you track, but we do not guarantee the accuracy, completeness, or availability of pricing information. Prices are subject to change at any time and without notice, and we are not responsible for any inaccuracies or errors in the data provided.`,
  },
  {
    title: '7. Notifications',
    content: `By using the Service, you consent to receive email notifications regarding price changes for the items you are tracking. You can manage your notification preferences through your account settings.`,
  },
  {
    title: '8. Purchasing Trackers and Payments',
    content: `Thrill operates on a tracker-based system, where you can purchase a set number of trackers to monitor the prices of items. Each tracker allows you to track the price of one item at a time.
    - You can purchase trackers in packages of various sizes, depending on your needs.
    - Once you assign a tracker to an item, it will continue to monitor the price of that item for 30 days or until you choose to stop tracking it. 
    - Payment for trackers is required upfront at the time of purchase. The cost of trackers may vary depending on the package you select.`,
  },
  {
    title: '9. Termination',
    content: `Thrill reserves the right to suspend or terminate your account at any time, with or without notice, for any reason, including, but not limited to, a violation of these Terms. Upon termination, your right to use the Service will immediately cease.`,
  },
  {
    title: '10. Intellectual Property',
    content: `All content, trademarks, service marks, logos, and intellectual property related to the Service are the property of Thrill or its licensors. You may not use, copy, or distribute any of this content without prior written permission.`,
  },
  {
    title: '11. Privacy',
    content: `Your privacy is important to us. Please review our Privacy Policy to understand how we collect, use, and share information about you.`,
  },
  {
    title: '12. Limitation of Liability',
    content: `To the maximum extent permitted by law, Thrill shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the Service; (ii) any unauthorized access to or use of our servers; (iii) any bugs, viruses, or the like that may be transmitted to or through the Service by any third party; or (iv) any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the Service.`,
  },
  {
    title: '13. Indemnification',
    content: `You agree to indemnify and hold harmless Thrill and its affiliates, officers, agents, employees, and partners from and against any claims, liabilities, damages, losses, and expenses, including without limitation reasonable attorney’s fees and costs, arising out of or in any way connected with your access to or use of the Service or your violation of these Terms.`,
  },
  {
    title: '14. Modifications to Terms',
    content: `Thrill reserves the right to modify these Terms at any time. If we make changes, we will provide notice by posting the updated Terms on our website and updating the “Last Updated” date at the top of this document. Your continued use of the Service following the posting of any changes constitutes acceptance of those changes.`,
  },
  {
    title: '15. Governing Law',
    content: `These Terms shall be governed by and construed in accordance with laws, without regard to its conflict of law principles. Any legal action or proceeding arising under these Terms will be brought exclusively in the federal or state courts, and the parties consent to the jurisdiction of such courts.`,
  },
  {
    title: '16. Contact Information',
    content: (
      <>
        If you have any questions or concerns about these Terms, please contact our{' '}
        <a className='redLink' href="/support">Support</a>.
      </>
    ),
  },
];

const TermsOfService = () => {

  return (
    <div>
      <NavBar />
      <h1 className='capText'>Terms of Use</h1>
      {termsSections.map((section, index) => (
          <div key={index} className="terms-section">
          <h2 className="terms-title">{section.title}</h2>
          <p className="terms-content">{section.content}</p>
          </div>
      ))}

      <div className="footerHero">
        <h2 className='redText capText'>Ready to track the prices of your favorite items?</h2>
        <button className='btnBasic btnBig btnBlack' onClick={() => window.location.href="/"}>
            Yeah, Let's do it!
        </button>
      </div>

      <Footer />

    </div>
  );
};

export default TermsOfService;
