import React from 'react';

const Argos = () => {
  return (
    <svg width="66" height="50" viewBox="0 0 66 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M65.7841 47.5V2.5H0V47.5H65.7841Z" fill="#EE3224"/>
        <path d="M32.1524 38.9427C45.2244 38.9427 54.4596 33.7756 58.4121 31.2113C59.0869 30.7872 58.7013 30.0931 57.9686 30.3823C47.8851 34.5275 41.3491 36.0507 32.1524 36.0507C22.9558 36.0507 16.4198 34.5275 6.33621 30.3823C5.60356 30.0931 5.21796 30.7679 5.89277 31.2113C9.86449 33.7756 19.0804 38.9427 32.1524 38.9427Z" fill="white"/>
        <path d="M50.6794 19.9849C50.6794 21.7202 52.8195 22.2215 53.9184 22.4914C54.4583 22.6263 55.5573 22.8963 55.5765 23.5903C55.6151 24.3423 54.7475 24.7472 53.8799 24.7472C52.9737 24.7472 52.3953 24.3808 51.894 23.7253C51.6627 23.4361 51.3927 23.359 51.0457 23.4747C50.6408 23.5903 50.1974 23.8217 49.7732 24.1109C49.5226 24.2844 49.4262 24.5544 49.5611 24.8821C50.2552 26.5402 52.1061 27.0608 53.7064 27.0608C56.2321 27.0608 58.43 25.9618 58.9699 23.7253C59.5097 21.5274 57.7166 20.7176 56.0971 20.2549C55.0367 19.9464 54.2462 19.6957 54.304 18.9824C54.3619 18.2304 56.0778 17.7484 57.2539 19.1173C57.5431 19.4644 57.8516 19.5029 58.1987 19.368C58.565 19.233 59.1627 18.9631 59.3555 18.8281C59.7603 18.5775 59.8375 18.3461 59.6061 17.922C59.0084 16.8423 57.5431 16.1289 55.885 16.1289C52.7424 16.1675 50.6794 17.8063 50.6794 19.9849Z" fill="white"/>
        <path d="M15.7974 15.4324C15.8553 17.1869 16.0288 21.255 16.0288 21.255H11.9028C12.8283 19.5198 14.4478 17.0134 15.6625 15.4131C15.7589 15.2396 15.7974 15.4324 15.7974 15.4324ZM15.9902 12.0969C15.3154 12.1355 14.7563 12.1933 14.2357 12.8103C12.5584 14.7961 8.70232 20.7537 5.65604 26.1715C5.44396 26.5571 5.5982 26.9234 6.06092 26.9234C6.58149 26.9234 8.04678 26.9234 8.29742 26.9234C8.66375 26.9234 8.91439 26.8463 9.16503 26.4221C9.43495 25.9594 10.0905 24.8026 10.6689 23.8193H16.0095C16.0095 23.8193 16.0673 25.6509 16.0866 26.3257C16.1059 26.692 16.318 26.9234 16.8 26.9234C17.282 26.9234 18.4388 26.9234 18.7666 26.9234C19.0943 26.9234 19.4028 26.7691 19.3835 26.3064C19.3257 24.3977 18.8437 15.0275 18.6702 12.8296C18.6316 12.2897 18.3617 12.0583 17.7447 12.0583C17.147 12.0391 16.4722 12.0583 15.9902 12.0969Z" fill="white"/>
        <path d="M43.3438 24.7979C45.214 24.7979 46.0238 23.2555 46.5058 21.6167C46.9685 19.9779 47.1613 18.4355 45.2719 18.4355C43.3824 18.4355 42.5919 19.9779 42.1292 21.6167C41.6279 23.2748 41.4544 24.7979 43.3438 24.7979ZM43.1318 27.0923C39.777 27.0923 38.2346 24.8751 38.9094 21.636C39.6035 18.3969 42.0906 16.1797 45.4454 16.1797C48.8194 16.1797 50.3618 18.3969 49.6677 21.636C48.9929 24.8751 46.5058 27.0923 43.1318 27.0923Z" fill="white"/>
        <path d="M36.9236 25.7017C37.7334 22.5012 38.4853 19.4742 38.9866 17.3148C39.1023 16.7943 38.7938 16.4472 38.3889 16.4472C38.0997 16.4472 37.2514 16.4472 36.9815 16.4665C36.5188 16.4858 36.2488 16.7171 36.1332 17.1027C36.1139 17.1991 36.0753 17.2955 36.0175 17.5269C35.9982 17.6233 35.9596 17.6233 35.9211 17.5462C35.7283 17.1606 34.8607 16.293 33.3568 16.293C30.619 16.293 28.7296 18.9344 27.9391 21.3829C27.4378 22.9254 27.3992 24.6991 28.0355 25.6631C28.6139 26.5308 29.5393 27.0899 30.9661 27.1092C32.1614 27.1284 33.2026 26.7236 33.7231 26.068C33.781 25.9909 33.8388 26.0295 33.8195 26.1066C33.1254 28.4973 31.6601 29.1529 30.5226 29.1529C29.7707 29.1529 29.2308 28.8444 28.9609 28.6709C28.5368 28.4202 28.4018 28.4009 28.0162 28.4973C27.592 28.613 27.0907 28.8058 26.6859 29.0757C26.2038 29.4035 26.3003 29.7313 26.4738 30.0205C27.3799 31.4858 29.4815 31.8521 30.7154 31.8521C33.7617 31.8521 35.9018 29.7506 36.9236 25.7017ZM30.619 21.7107C31.2167 19.6092 32.2386 18.5295 33.6267 18.568C35.4005 18.6259 35.0342 20.3611 34.6486 21.6914C34.0509 23.7158 33.1254 24.7955 31.6794 24.6799C30.1949 24.5449 30.2913 22.829 30.619 21.7107Z" fill="white"/>
        <path d="M29.5688 16.9315C29.7616 16.758 29.6459 16.4688 29.3375 16.4688C27.6022 16.4688 25.9441 16.4687 24.7102 17.6063C24.6909 17.6256 24.6331 17.6256 24.6524 17.5677C24.6716 17.4713 24.7295 17.2592 24.7873 17.0086C24.8644 16.7194 24.6909 16.4688 24.3632 16.4688C23.8426 16.4688 23.3028 16.4688 22.6665 16.488C22.2809 16.488 22.0688 16.6423 21.9724 17.0664C21.7796 17.934 20.4493 24.4893 20.0637 26.4559C20.0058 26.7258 20.2372 26.9379 20.4686 26.9379C20.6806 26.9379 22.3387 26.9379 22.5315 26.9379C22.8979 26.9379 23.0907 26.7644 23.1485 26.4559C23.4763 24.8942 23.9004 22.7927 24.0354 22.06C24.2475 20.9803 24.6909 20.132 25.1151 19.8042C25.52 19.4957 26.0791 19.2837 27.1974 19.2258C27.6986 19.2065 27.8914 19.0523 28.1614 18.6667C28.5855 17.9919 28.9711 17.4906 29.5688 16.9315Z" fill="white"/>
    </svg>
    );
}; 

export default Argos;