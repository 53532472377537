import React from 'react';

const Target = () => {
  return (
    <svg width="136" height="50" viewBox="0 0 136 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.9965 35.0049C9.47248 35.0049 4.98957 30.5291 4.98957 25.0042C4.98957 19.4693 9.47248 14.9968 14.9965 14.9968C20.5253 14.9968 25.0026 19.4693 25.0026 25.0042C25.0026 30.5291 20.5253 35.0049 14.9965 35.0049ZM14.9965 10C6.71534 10 0 16.717 0 25.0042C0 33.2851 6.71534 40 14.9965 40C23.2812 40 30.0071 33.2851 30.0071 25.0042C30.0071 16.717 23.2812 10 14.9965 10Z" fill="#CC0000"/>
        <path d="M14.996 19.8633C12.1574 19.8633 9.85742 22.1577 9.85742 25.0048C9.85742 27.8426 12.1573 30.1438 14.996 30.1438C17.8372 30.1438 20.1422 27.8425 20.1422 25.0048C20.1422 22.1577 17.8372 19.8633 14.996 19.8633Z" fill="#CC0000"/>
        <path d="M42.9158 18.9855H37.5527V15.6758H52.2141V18.9855H46.851V33.5719H42.9158V18.9855Z" fill="#CC0000"/>
        <path d="M50.5098 33.5719H54.493L55.8977 29.5857H62.5898L63.9437 33.5719H68.0283L61.3362 15.6758H57.3019L50.5098 33.5719ZM59.2565 20.0855H59.3057L61.5605 26.6533H56.927L59.2565 20.0855Z" fill="#CC0000"/>
        <path d="M69.5762 33.5709H73.5132V26.5784H77.4457C79.4276 26.5784 80.153 27.4048 80.428 29.2837C80.6309 30.7128 80.5799 32.4426 81.0566 33.5712H84.9893C84.2883 32.569 84.3142 30.4609 84.2381 29.3335C84.1121 27.5263 83.563 25.6487 81.6316 25.1478V25.0983C83.614 24.2968 84.4653 22.7196 84.4653 20.6114C84.4653 17.9066 82.4336 15.6758 79.2265 15.6758H69.5764L69.5762 33.5709ZM73.5132 18.7326H77.8248C79.5765 18.7326 80.5296 19.4848 80.5296 21.2123C80.5296 23.016 79.5765 23.7693 77.8248 23.7693H73.5132V18.7326Z" fill="#CC0000"/>
        <path d="M100.105 31.5388C98.7252 33.3209 96.9237 34 95.1935 34C89.6557 34 86.3965 29.8578 86.3965 24.6944C86.3965 19.3836 89.6554 15.25 95.1935 15.25C98.8516 15.25 102.312 17.5055 102.739 21.5624H98.9774C98.5253 19.5805 97.0469 18.5567 95.1935 18.5567C91.6592 18.5567 90.329 21.5624 90.329 24.6944C90.329 27.6785 91.6592 30.6847 95.1935 30.6847C97.7507 30.6847 99.2263 29.3072 99.453 26.8244H95.4949V23.8954H103.012V33.5701H100.504L100.105 31.5388Z" fill="#CC0000"/>
        <path d="M105.693 15.6758H119.072V18.9855H109.628V22.8184H118.298V25.879H109.628V30.2622H119.275V33.5719H105.693V15.6758Z" fill="#CC0000"/>
        <path d="M126.397 18.9855H121.033V15.6758H135.697V18.9855H130.331V33.5719H126.397V18.9855Z" fill="#CC0000"/>
    </svg>
    );
}; 

export default Target;