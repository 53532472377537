import React from 'react';

const Amazon = () => {
  return (
    <svg width="100" height="50" viewBox="0 0 100 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M61.7577 33.4611C55.9729 37.725 47.588 40 40.3688 40C30.2464 40 21.1336 36.256 14.2394 30.0292C13.6977 29.5395 14.183 28.8722 14.833 29.2535C22.2732 33.5824 31.4727 36.1867 40.9755 36.1867C47.3844 36.1867 54.4346 34.8607 60.9171 32.1091C61.8964 31.6931 62.7154 32.7504 61.7577 33.4611Z" fill="#FF9900"/>
        <path d="M64.1616 30.7109C63.4249 29.7663 59.2737 30.2646 57.4104 30.4856C56.8427 30.5549 56.756 30.0609 57.2674 29.7056C60.5736 27.3786 65.9989 28.0503 66.6315 28.8303C67.2642 29.6146 66.4669 35.0528 63.3599 37.6484C62.8833 38.0471 62.4283 37.8348 62.6406 37.3061C63.3383 35.5641 64.9026 31.6599 64.1616 30.7109Z" fill="#FF9900"/>
        <path d="M57.5412 13.2753V11.0134C57.5412 10.6711 57.8012 10.4414 58.1132 10.4414H68.24C68.5649 10.4414 68.8249 10.6754 68.8249 11.0134V12.9504C68.8206 13.2753 68.5476 13.7 68.0623 14.3717L62.8147 21.8638C64.7647 21.8162 66.823 22.1065 68.5909 23.1032C68.9896 23.3285 69.0979 23.6578 69.1283 23.9828V26.3964C69.1283 26.7258 68.7643 27.1114 68.383 26.9121C65.2673 25.2784 61.1291 25.1008 57.6842 26.9294C57.3332 27.1201 56.9648 26.7388 56.9648 26.4094V24.1171C56.9648 23.7488 56.9692 23.1205 57.3375 22.5615L63.417 13.843H58.1262C57.8012 13.843 57.5412 13.6133 57.5412 13.2753Z" fill="#221F1F"/>
        <path d="M20.6 27.39H17.5191C17.2244 27.3683 16.9904 27.1473 16.9688 26.8657V11.0537C16.9688 10.7373 17.2331 10.486 17.5624 10.486H20.4353C20.7343 10.499 20.9727 10.7287 20.9943 11.0147V13.0816H21.0507C21.8003 11.084 23.2086 10.1523 25.1066 10.1523C27.0349 10.1523 28.2395 11.084 29.1062 13.0816C29.8515 11.084 31.5458 10.1523 33.3614 10.1523C34.6527 10.1523 36.0654 10.6853 36.9277 11.8813C37.9027 13.2116 37.7033 15.1442 37.7033 16.8385L37.699 26.818C37.699 27.1343 37.4347 27.39 37.1054 27.39H34.0287C33.7211 27.3683 33.4741 27.1213 33.4741 26.818V18.4375C33.4741 17.7702 33.5348 16.1062 33.3874 15.4736C33.1578 14.4119 32.4688 14.1129 31.5761 14.1129C30.8308 14.1129 30.0508 14.6113 29.7345 15.4086C29.4182 16.2059 29.4485 17.5405 29.4485 18.4375V26.818C29.4485 27.1343 29.1842 27.39 28.8548 27.39H25.7782C25.4662 27.3683 25.2236 27.1213 25.2236 26.818L25.2193 18.4375C25.2193 16.6739 25.5096 14.0783 23.3213 14.0783C21.107 14.0783 21.1937 16.6089 21.1937 18.4375V26.818C21.1937 27.1343 20.9293 27.39 20.6 27.39Z" fill="#221F1F"/>
        <path d="M77.5445 10.1523C82.1161 10.1523 84.5904 14.0783 84.5904 19.0702C84.5904 23.8931 81.8561 27.7193 77.5445 27.7193C73.0553 27.7193 70.6113 23.7934 70.6113 18.9012C70.6113 13.9786 73.0856 10.1523 77.5445 10.1523ZM77.5705 13.3806C75.2999 13.3806 75.1569 16.4746 75.1569 18.4028C75.1569 20.3355 75.1266 24.4607 77.5445 24.4607C79.9321 24.4607 80.0448 21.1328 80.0448 19.1048C80.0448 17.7702 79.9885 16.1756 79.5855 14.9102C79.2388 13.8096 78.5498 13.3806 77.5705 13.3806Z" fill="#221F1F"/>
        <path d="M90.5176 27.39H87.4496C87.1419 27.3683 86.895 27.1213 86.895 26.818L86.8906 11.0017C86.9166 10.7113 87.1723 10.486 87.4843 10.486H90.3399C90.6085 10.499 90.8295 10.681 90.8902 10.928V13.3459H90.9465C91.8089 11.1837 93.0178 10.1523 95.1455 10.1523C96.5278 10.1523 97.8754 10.6507 98.7421 12.0156C99.548 13.2809 99.548 15.4086 99.548 16.9382V26.8917C99.5134 27.169 99.2577 27.39 98.9544 27.39H95.8648C95.5831 27.3683 95.3491 27.1603 95.3188 26.8917V18.3032C95.3188 16.5742 95.5181 14.0436 93.3905 14.0436C92.6408 14.0436 91.9519 14.5463 91.6095 15.3089C91.1762 16.2752 91.1199 17.2372 91.1199 18.3032V26.818C91.1155 27.1343 90.8469 27.39 90.5176 27.39Z" fill="#221F1F"/>
        <path d="M49.4668 19.8365V19.1692C47.2395 19.1692 44.8866 19.6458 44.8866 22.2718C44.8866 23.6021 45.5756 24.5034 46.7585 24.5034C47.6252 24.5034 48.4008 23.9704 48.8905 23.1037C49.4972 22.0378 49.4668 21.0368 49.4668 19.8365ZM52.5738 27.346C52.3701 27.528 52.0754 27.541 51.8458 27.4197C50.8231 26.5703 50.6411 26.176 50.0778 25.3657C48.3878 27.0903 47.1919 27.606 44.9992 27.606C42.408 27.606 40.3887 26.007 40.3887 22.8047C40.3887 20.3045 41.745 18.6015 43.6733 17.7695C45.3459 17.0329 47.6815 16.9029 49.4668 16.6992V16.3005C49.4668 15.5682 49.5232 14.7016 49.0942 14.0689C48.7172 13.5013 47.9979 13.2673 47.3652 13.2673C46.1909 13.2673 45.1422 13.8696 44.8866 15.1176C44.8346 15.3949 44.6309 15.6679 44.3536 15.6809L41.3637 15.3602C41.1123 15.3039 40.835 15.1002 40.9043 14.7146C41.5933 11.092 44.8649 10 47.7942 10C49.2935 10 51.2521 10.3987 52.4351 11.534C53.9344 12.9336 53.7914 14.8012 53.7914 16.8335V21.6348C53.7914 23.0777 54.3894 23.7104 54.9527 24.4904C55.152 24.7677 55.1954 25.1014 54.944 25.3094C54.3157 25.8337 53.1978 26.8087 52.5824 27.3547L52.5738 27.346Z" fill="#221F1F"/>
        <path d="M9.07815 19.8365V19.1692C6.85086 19.1692 4.49791 19.6458 4.49791 22.2718C4.49791 23.6021 5.18689 24.5034 6.36987 24.5034C7.23652 24.5034 8.01217 23.9704 8.50183 23.1037C9.10848 22.0378 9.07815 21.0368 9.07815 19.8365ZM12.1851 27.346C11.9814 27.528 11.6868 27.541 11.4571 27.4197C10.4345 26.5703 10.2525 26.176 9.68914 25.3657C7.99917 27.0903 6.8032 27.606 4.61057 27.606C2.01929 27.606 0 26.007 0 22.8047C0 20.3045 1.35631 18.6015 3.2846 17.7695C4.95723 17.0329 7.29285 16.9029 9.07815 16.6992V16.3005C9.07815 15.5682 9.13448 14.7016 8.70549 14.0689C8.3285 13.5013 7.60918 13.2673 6.97653 13.2673C5.80222 13.2673 4.75357 13.8696 4.49791 15.1176C4.44591 15.3949 4.24225 15.6679 3.96492 15.6809L0.974981 15.3602C0.723652 15.3039 0.446324 15.1002 0.515656 14.7146C1.20464 11.092 4.47624 10 7.40552 10C8.90482 10 10.8634 10.3987 12.0464 11.534C13.5457 12.9336 13.4027 14.8012 13.4027 16.8335V21.6348C13.4027 23.0777 14.0007 23.7104 14.564 24.4904C14.7634 24.7677 14.8067 25.1014 14.5554 25.3094C13.9271 25.8337 12.8091 26.8087 12.1938 27.3547L12.1851 27.346Z" fill="#221F1F"/>
    </svg>
    );
}; 

export default Amazon;