import React, { useState } from 'react';
import './ProductFAQ.css';

const faqs = [
  {
    question: "How does Thrill track prices?",
    answer: "Thrill continuously monitors online stores for price changes on your tracked items and updates you via email whenever there is a change."
  },
  {
    question: "How often are prices checked?",
    answer: "Prices are checked multiple times throughout the day to ensure you get the latest updates as soon as possible."
  },
  {
    question: "Which online stores does Thrill support?",
    answer: "Thrill supports a wide range of popular online retailers continuously adding new stores. Check our complete list of supported stores."
  },
  {
    question: "Is there a limit to the number of items I can track?",
    answer: "There’s no theoretical limit to the number of items you can track; pricing is based on the number of trackers you use. Feel free to monitor as many items as you like!"
  },
  {
    question: "Can I stop tracking an item?",
    answer: "Yes, you can stop tracking any item at any time through your account dashboard."
  },
  {
    question: "Will I be notified of both price increases and decreases?",
    answer: "Yes, Thrill notifies you of any price change, whether it’s an increase or a decrease."
  },
  {
    question: "Can I track items in different currencies?",
    answer: "Yes, Thrill supports multiple currencies and will track the price in the currency of the respective online store."
  },
  {
    question: "Is there a way to see the price history of tracked items?",
    answer: "Yes, Thrill provides a price history chart for each tracked item so you can see how the price has changed over time."
  }
];

const FAQComponent = () => {
    const [openFAQIndex, setOpenFAQIndex] = useState(null);
  
    const toggleFAQ = (index) => {
      setOpenFAQIndex(openFAQIndex === index ? null : index);
    };
  
    return (
      <div className="faq-container redBG">
        <h2 className='capText noMarginP marginB'><span className='lightText'>Got More Questions?</span><br/>We answered the most common</h2>

        <div className='marginT'>
          {faqs.map((faq, index) => (
            <div key={index} className="faq-item">
              <div
                className="faq-question"
                onClick={() => toggleFAQ(index)}
                >
                {faq.question}
                <span className="faq-icon">{openFAQIndex === index ? '-' : '+'}</span>
              </div>
              <div
                className={`faq-answer ${openFAQIndex === index ? 'show' : 'hide'}`}
                >
                <p className='noMarginP lightText'>
                  {faq.answer}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

export default FAQComponent;
