import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://ryoxnujokdogqjyfhavj.supabase.co';

let supabase = null;

const getSupabaseClient = async () => {
  if (!supabase) {
    const response = await fetch('/getSupabaseKey'); // Fetch key from Cloudflare function
    const { key: supabaseKey } = await response.json(); // Assuming the response contains `{ key: 'your-supabase-key' }`
    
    // Initialize Supabase client only once
    supabase = createClient(supabaseUrl, supabaseKey);
  }
  return supabase;
};

await getSupabaseClient(); // Ensure it's initialized before export

export default supabase;
