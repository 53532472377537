import React from 'react';

const BananaRepublic = () => {
  return (
    <svg width="302" height="50" viewBox="0 0 302 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.9561 19.3062C11.9561 23.2598 7.81315 23.5718 6.01633 23.5718C5.13655 23.5718 4.47691 23.5122 4.47691 23.5122V15.6065C4.47691 15.6065 5.31999 15.5407 6.89658 15.5407C9.31618 15.5407 11.9561 16.5765 11.9561 19.3062ZM17.3694 29.7217C17.3694 24.5232 10.105 24.0812 10.105 24.0812C10.105 24.0812 15.0604 22.8209 15.0604 19.1188C15.0604 14.909 9.46291 14.2695 6.96936 14.2695H0C0.30428 14.3513 0.571657 14.5343 0.75815 14.7882C0.944644 15.0422 1.03915 15.3521 1.02612 15.6669V34.2235C1.03167 34.5403 0.934793 34.8504 0.749912 35.1078C0.565032 35.3651 0.302021 35.5559 0 35.6517H7.9227C15.7738 35.6517 17.3694 32.1773 17.3694 29.7217ZM13.8655 29.4066C13.8655 34.0965 8.50949 34.3829 6.20056 34.3829C5.13655 34.3829 4.47691 34.3132 4.47691 34.3132V24.9684C4.47691 24.9684 6.27333 24.9064 7.41059 24.9064C10.489 24.9064 13.8655 25.7232 13.8655 29.4066Z" fill="#231F20"/>
        <path d="M241.963 19.3062C241.963 23.2598 237.821 23.5718 236.022 23.5718C235.143 23.5718 234.482 23.5122 234.482 23.5122V15.6065C234.482 15.6065 235.327 15.5407 236.905 15.5407C239.323 15.5407 241.963 16.5765 241.963 19.3062ZM247.375 29.7217C247.375 24.5232 240.112 24.0812 240.112 24.0812C240.112 24.0812 245.067 22.8209 245.067 19.1188C245.067 14.909 239.468 14.2695 236.979 14.2695H230.006C230.31 14.3512 230.578 14.5341 230.764 14.7881C230.951 15.0421 231.045 15.352 231.032 15.6669V34.2235C231.038 34.5403 230.941 34.8505 230.756 35.1079C230.571 35.3652 230.308 35.556 230.006 35.6517H237.929C245.781 35.6517 247.375 32.1773 247.375 29.7217ZM243.871 29.4066C243.871 34.0965 238.515 34.3829 236.208 34.3829C235.143 34.3829 234.482 34.3132 234.482 34.3132V24.9684C234.482 24.9684 236.28 24.9064 237.416 24.9064C240.496 24.9064 243.871 25.7232 243.871 29.4066Z" fill="#231F20"/>
        <path d="M32.698 27.9264H24.4536C26.0654 24.534 28.5579 19.1281 28.5579 19.1281C28.5579 19.1281 30.794 23.9682 32.698 27.9264ZM41.4205 35.6494C40.1377 35.147 39.8799 34.7692 39.5869 34.1089C39.0008 32.9112 29.5472 13.7539 29.5472 13.7539C29.5472 13.7539 20.1292 32.9112 19.5064 34.1089C19.1209 34.7874 18.5307 35.3266 17.8203 35.6494H22.2903C21.3385 35.3336 21.2296 34.8319 21.5227 34.1089C21.6683 33.7303 22.6208 31.6524 23.8301 29.2913H33.3217C34.4566 31.7167 35.4824 33.7303 35.7037 34.1089C35.9964 34.8319 35.7398 35.2709 34.7871 35.6494H41.4205Z" fill="#231F20"/>
        <path d="M63.145 15.6885V36.2471C63.145 36.2471 45.6731 18.0791 45.6731 17.7648V34.2327C45.6691 34.5489 45.7675 34.858 45.9535 35.1137C46.1396 35.3694 46.4033 35.5581 46.7054 35.6517H43.011C43.2912 35.5346 43.5307 35.3376 43.6997 35.0852C43.8687 34.8329 43.9597 34.5364 43.9614 34.2327V16.8823C43.9614 15.9742 43.9614 15.9115 43.522 15.4067C43.051 14.9067 42.4743 14.5182 41.834 14.2695H46.8846L61.3969 29.5831V15.6885C61.4028 15.3824 61.3142 15.0819 61.1431 14.828C60.972 14.5741 60.7268 14.3791 60.4409 14.2695H64.1351C63.8413 14.3711 63.5873 14.5631 63.4094 14.8181C63.2315 15.073 63.1389 15.3777 63.145 15.6885Z" fill="#231F20"/>
        <path d="M80.132 27.9288H71.8869C73.4994 24.5356 75.9919 19.1281 75.9919 19.1281C75.9919 19.1281 78.2272 23.972 80.132 27.9288ZM88.8545 35.6494C87.5717 35.1455 87.3147 34.7723 87.0224 34.1096C86.4348 32.9135 76.9812 13.7539 76.9812 13.7539C76.9812 13.7539 67.5628 32.9135 66.9404 34.1096C66.555 34.7881 65.9646 35.3272 65.2539 35.6494H69.725C68.7725 35.3336 68.6621 34.8342 68.9559 34.1096C69.103 33.7319 70.0544 31.6548 71.264 29.2928H80.7549C81.8905 31.7167 82.9171 33.7319 83.1377 34.1096C83.4319 34.8342 83.1741 35.2709 82.2211 35.6494H88.8545Z" fill="#231F20"/>
        <path d="M110.731 15.6932V36.251C110.731 36.251 93.2602 18.0791 93.2602 17.7656V34.2358C93.2547 34.5513 93.3519 34.8601 93.5372 35.1155C93.7225 35.3709 93.9858 35.5591 94.2875 35.6517H90.5939C90.8747 35.536 91.1151 35.3398 91.2847 35.0879C91.4544 34.836 91.5459 34.5396 91.5477 34.2358V16.8861C91.5477 15.9757 91.5477 15.9146 91.1083 15.4106C90.6383 14.9093 90.0621 14.5194 89.4219 14.2695H94.4709L108.983 29.5855V15.6932C108.989 15.3863 108.901 15.0849 108.73 14.8301C108.559 14.5753 108.313 14.3795 108.026 14.2695H111.721C111.426 14.3718 111.172 14.5646 110.994 14.8204C110.816 15.0762 110.724 15.3817 110.731 15.6932Z" fill="#231F20"/>
        <path d="M127.342 27.9256H119.096C120.71 24.5324 123.201 19.1273 123.201 19.1273C123.201 19.1273 125.435 23.9658 127.342 27.9256ZM136.063 35.6509C134.78 35.1462 134.523 34.7668 134.231 34.1057C133.644 32.9096 124.189 13.75 124.189 13.75C124.189 13.75 114.771 32.9096 114.149 34.1057C113.766 34.7874 113.175 35.3288 112.463 35.6509H116.934C115.981 35.3382 115.873 34.8295 116.164 34.1057C116.312 33.7272 117.264 31.6516 118.473 29.2896H127.964C129.1 31.7135 130.127 33.7272 130.346 34.1057C130.639 34.8295 130.384 35.2754 129.429 35.6509H136.063Z" fill="#231F20"/>
        <path d="M159.27 19.5895C159.27 22.8913 156.521 24.3057 153.733 24.3057C152.776 24.3057 151.605 24.2453 151.605 24.2453V15.6111C151.605 15.6111 152.705 15.4973 154.354 15.4973C158.241 15.4973 159.27 18.1093 159.27 19.5895ZM168.588 35.6517C167.738 35.3748 166.944 34.9491 166.242 34.3945C164.666 32.9135 160.74 27.1522 160.74 27.1522C159.907 25.9288 158.629 25.0785 157.179 24.7818C159.456 24.6239 162.543 22.8913 162.543 19.4943C162.543 17.5117 161.393 14.2695 154.053 14.2695H147.162C147.451 14.3691 147.702 14.5562 147.88 14.805C148.057 15.0538 148.153 15.3518 148.153 15.6575V34.2358C148.144 34.5428 148.045 34.8403 147.869 35.0918C147.693 35.3434 147.447 35.538 147.162 35.6517H152.633C152.343 35.5396 152.092 35.3463 151.91 35.0952C151.728 34.8441 151.622 34.5457 151.605 34.2358V25.5737H153.733C155.638 25.5737 156.409 26.9293 157.362 28.1912C158.644 29.9547 159.968 31.8754 161.842 34.2358C162.205 34.6829 162.664 35.0424 163.185 35.2875C163.705 35.5327 164.275 35.6572 164.85 35.6517H168.588Z" fill="#231F20"/>
        <path d="M185.462 35.6517H170.723C171.038 35.5677 171.316 35.3822 171.515 35.124C171.714 34.8659 171.822 34.5493 171.823 34.2235L171.827 15.697C171.826 15.3816 171.727 15.0744 171.542 14.8184C171.358 14.5624 171.098 14.3705 170.799 14.2695H184.585L184.655 16.7066C184.256 16.3063 183.772 16 183.24 15.8097C182.708 15.6194 182.14 15.55 181.577 15.6065H175.274V23.1282H181.247C181.515 23.0928 181.772 22.9944 181.994 22.841C182.217 22.6876 182.4 22.4836 182.529 22.2457V25.3075C182.4 25.0697 182.217 24.8659 181.994 24.7127C181.771 24.5594 181.515 24.4611 181.247 24.4257H175.274V34.3132H179.533C183.49 34.2823 184.875 34.2869 186.232 32.3112L185.462 35.6517Z" fill="#231F20"/>
        <path d="M202.42 20.167C202.42 24.4775 198.144 25.0768 196.169 25.0768C195.564 25.0941 194.96 25.0195 194.378 24.8554V15.6065C195.192 15.5274 196.011 15.4943 196.829 15.5074C200.518 15.5074 202.42 17.3019 202.42 20.167ZM205.557 20.1051C205.557 17.6487 204.245 14.2695 196.93 14.2695H189.931C190.225 14.3766 190.48 14.5697 190.663 14.8238C190.846 15.0778 190.948 15.381 190.956 15.6939L190.949 34.2327C190.932 34.543 190.827 34.8418 190.644 35.0935C190.462 35.3452 190.211 35.539 189.922 35.6517H195.385C195.089 35.5507 194.831 35.3598 194.648 35.1054C194.465 34.8511 194.366 34.5461 194.364 34.2327V25.8609C196.749 26.553 199.29 26.4864 201.636 25.6705C202.765 25.2326 203.74 24.4707 204.437 23.4805C205.135 22.4903 205.524 21.316 205.557 20.1051Z" fill="#231F20"/>
        <path d="M226.598 15.6939V28.867C226.563 34.0253 222.723 35.9498 218.14 35.9498C214.11 35.9498 208.936 34.2397 208.936 28.9282V15.6932C208.907 15.3709 208.784 15.0644 208.582 14.8113C208.38 14.5583 208.108 14.37 207.801 14.2695H213.672C213.352 14.3606 213.067 14.5445 212.851 14.7979C212.636 15.0513 212.501 15.3629 212.463 15.6932V28.9893C212.463 31.1593 213.34 34.4913 218.507 34.4913C223.419 34.4913 225.006 31.8235 224.971 28.9932L224.962 15.6932C224.975 15.3853 224.89 15.0811 224.719 14.8249C224.547 14.5688 224.298 14.3742 224.009 14.2695H227.585C227.292 14.3728 227.038 14.566 226.861 14.8218C226.684 15.0776 226.592 15.3828 226.598 15.6939Z" fill="#231F20"/>
        <path d="M265.119 35.6517H250.527C250.825 35.5483 251.084 35.3553 251.267 35.0991C251.451 34.843 251.551 34.5363 251.553 34.2211V15.6854C251.559 15.3701 251.462 15.0613 251.277 14.8058C251.092 14.5503 250.829 14.3621 250.527 14.2695H256.031C255.737 14.3703 255.483 14.5617 255.305 14.8162C255.127 15.0706 255.035 15.375 255.041 15.6854V34.3132H258.961C263.358 34.2807 264.604 34.2211 265.851 32.3058L265.119 35.6517Z" fill="#231F20"/>
        <path d="M269.061 35.6517C269.349 35.5418 269.598 35.3475 269.774 35.094C269.95 34.8406 270.046 34.5398 270.048 34.2312V15.6839C270.055 15.3738 269.963 15.0697 269.785 14.8154C269.608 14.5612 269.354 14.37 269.061 14.2695H274.49C274.205 14.3784 273.96 14.5728 273.79 14.826C273.62 15.0792 273.532 15.3789 273.539 15.6839V34.2312C273.541 34.5349 273.633 34.8313 273.802 35.0838C273.971 35.3362 274.21 35.5337 274.49 35.6517H269.061Z" fill="#231F20"/>
        <path d="M300.799 33.7888C297.93 35.2149 294.768 35.9511 291.565 35.9386C284.308 35.9386 277.922 31.5824 277.922 24.9998C277.922 19.6457 282.662 13.9355 291.969 13.9355C294.938 13.9988 297.869 14.6106 300.615 15.7401C300.615 15.7401 301.018 20.5275 300.945 20.4013C300.131 18.8117 298.882 17.4855 297.345 16.5766C295.808 15.6678 294.044 15.2134 292.259 15.2663C285.263 15.2663 281.67 19.4878 281.67 24.5918C281.67 29.1269 284.565 34.5429 292.223 34.5429C294.04 34.6364 295.846 34.2053 297.425 33.301C299.004 32.3967 300.289 31.0574 301.128 29.4427C301.201 29.3467 300.799 33.7888 300.799 33.7888Z" fill="#231F20"/>
    </svg>
    );
}; 

export default BananaRepublic;