import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ConfirmProduct = ({ productId, userID, trackers, setShowPlans }) => {
  const [productData, setProductDataState] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProductData = async () => {
        setLoading(true);
          const response = await fetch(`/getProduct?id=${productId}`);
          const data = await response.json();

          if (data.product) {
            setProductDataState(data.product);
            setLoading(false);
          } else {
            console.log("no product found")
          }
    };
    fetchProductData();
  }, [productId]);

  const handleConfirm = async () => {
    if (trackers === 0) {
      setShowPlans(true);
    } else {
      try {
        const payload = { productId, userID, priceText: productData.priceText };
        const response = await fetch('/confirmProduct', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        });
        console.log(response);
      } catch (error) {
        console.error('Error confirming product:', error);
      }
    }
  };

  const handleCancel = async () => {
      try {
        const response = await fetch(`/OutDelete?id=${productId}`, {
          method: 'DELETE'
        });
        console.log(response);
        navigate("/");
      } catch (error) {
        console.error('Error deleting product:', error);
      }
  };

  if (loading) {
    return <p>We are looking for the data of the product, please wait</p>;
  }

  if (!productData) {
    return <p>Data for this product has not been found</p>;
  }

  return (
    <div>
      <h3 className='centeredText'>Do you still want to track this item?</h3>
      <div className='hoFlex'>
        <img className='productImg' src={productData.productImg} alt={productData.productTitle} />
        <div className='fillContainer'>
          <p><strong>{productData.productTitle}</strong></p>
          <h3>{productData.productPrice}</h3>
          <div className='hFlexFit clmnG endPos'>
            <button className='btnBasic' onClick={handleConfirm}>Yes, let's track it!</button>
            <button className='btnBasic greyBtn' onClick={handleCancel}>No, I changed my mind</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmProduct;