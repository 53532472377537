import React from 'react';

const Ulta = () => {
  return (
    <svg width="84" height="50" viewBox="0 0 84 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M33.1349 32.577C33.1349 31.8876 33.1134 31.4136 33.0811 30.875C33.2319 30.875 34.4383 30.875 34.8153 30.875C35.6125 30.875 36.1511 31.1551 36.1511 31.8337C36.1511 32.5015 35.4832 32.7924 35.0739 32.8462V32.857C35.4724 32.8678 36.2588 33.1371 36.2588 33.8911C36.2588 34.7744 35.5047 35.1191 34.7184 35.1191C34.3629 35.1191 33.2211 35.1083 33.0703 35.1191C33.1026 34.5697 33.1242 34.1066 33.1242 33.4172L33.1349 32.577ZM34.4599 31.2197C34.266 31.2197 34.2337 31.2197 34.1367 31.2412C34.126 31.5429 34.1044 31.8876 34.1044 32.103C34.1044 32.3292 34.1152 32.5231 34.1152 32.7278H34.4168C34.7938 32.7278 35.1708 32.4692 35.1708 31.9522C35.1708 31.5429 34.9877 31.2197 34.4599 31.2197ZM34.406 33.0725C34.2875 33.0725 34.2014 33.0725 34.126 33.0725C34.126 33.2448 34.1152 33.4172 34.1152 33.6757C34.1152 34.0527 34.126 34.5374 34.126 34.7529C34.2444 34.7529 34.3522 34.7636 34.4922 34.7636C34.88 34.7636 35.2139 34.4728 35.2139 33.9234C35.2247 33.3633 34.9338 33.0725 34.406 33.0725Z" fill="#F37830"/>
        <path d="M42.8195 32.577C42.8195 31.8876 42.8087 31.4136 42.7656 30.875C43.1642 30.875 45.157 30.8858 45.3293 30.875C45.3186 30.9504 45.3186 31.3166 45.3293 31.4136C44.8338 31.3705 43.9936 31.3705 43.7997 31.3705C43.7674 31.8229 43.7997 32.2322 43.7997 32.6739C44.3814 32.6631 44.8123 32.6524 45.3078 32.6308C45.2862 32.7708 45.2862 33.0294 45.3078 33.1586C44.8123 33.1263 44.306 33.1048 43.7997 33.1048C43.7997 33.3633 43.7997 33.6326 43.7997 33.8911C43.7997 34.1496 43.7997 34.3651 43.8105 34.6021C44.306 34.6021 44.8231 34.6344 45.3293 34.5697C45.3186 34.6667 45.3186 35.0437 45.3293 35.1191C45.0923 35.1191 42.9164 35.1083 42.7656 35.1191C42.8087 34.5697 42.8195 34.1066 42.8195 33.4172V32.577Z" fill="#F37830"/>
        <path d="M55.5507 35.1192C55.3245 35.1084 54.5813 35.1084 54.4089 35.1192C54.2581 34.7098 54.1073 34.279 53.935 33.8696C53.6549 33.8589 52.6316 33.8589 52.4054 33.8696C52.2115 34.279 52.0822 34.7098 51.9422 35.1299C51.8452 35.1192 51.4359 35.1192 51.3066 35.1299C51.619 34.4513 52.61 32.2431 53.1917 30.8535C53.2779 30.8535 53.601 30.8535 53.698 30.8535C54.2689 32.2431 55.0445 34.1282 55.5507 35.1192ZM53.7949 33.5034C53.5903 32.9971 53.4179 32.5124 53.2025 32.0276C52.9763 32.5124 52.7716 32.9971 52.5669 33.5034H53.7949Z" fill="#F37830"/>
        <path d="M60.7862 33.5895C60.7862 32.9217 60.8077 32.5877 60.8077 32.1461C60.8077 31.489 60.7862 31.0797 60.7754 30.875C60.9585 30.8858 61.1416 30.8965 61.3248 30.8965C61.4971 30.8965 61.691 30.8858 61.8633 30.875C61.7772 31.6721 61.7664 32.3184 61.7664 33.0294C61.7664 34.5374 62.2404 34.7852 63.0913 34.7852C64.147 34.7852 64.2224 33.7188 64.2224 32.5662C64.2224 32.006 64.2008 31.4459 64.1685 30.875C64.287 30.8858 64.4055 30.8965 64.5132 30.8965C64.6317 30.8965 64.7394 30.8858 64.8364 30.875C64.7933 31.2305 64.761 32.1138 64.761 33.1909C64.761 34.6128 64.0931 35.2053 62.8113 35.2053C61.6802 35.2053 60.7862 34.7421 60.7862 33.5895Z" fill="#F37830"/>
        <path d="M71.7192 31.8758C71.7192 31.6496 71.7299 31.445 71.7084 31.3588C71.4606 31.3588 70.9005 31.3588 70.502 31.4019C70.5235 31.3157 70.5235 30.9602 70.502 30.8633C71.0944 30.8741 73.3349 30.8741 73.8951 30.8633C73.8735 30.9602 73.8735 31.3157 73.8951 31.4019C73.5396 31.3696 72.9579 31.3696 72.6994 31.3696C72.6994 31.4557 72.6994 31.6496 72.6994 31.8758V33.4162C72.6994 34.1056 72.721 34.5688 72.7533 35.1182C72.6025 35.1074 71.8161 35.1074 71.6653 35.1182C71.7084 34.5688 71.7192 34.1056 71.7192 33.4162V31.8758Z" fill="#F37830"/>
        <path d="M81.8439 32.717C82.1994 32.2538 82.5548 31.4782 82.8457 30.875C82.9534 30.875 83.3627 30.875 83.4812 30.875C83.1796 31.3382 82.501 32.5123 82.0916 33.2879C82.0916 33.4818 82.0809 33.6326 82.0809 33.8373C82.0809 34.2466 82.0916 34.6667 82.1455 35.1299C81.9624 35.1191 81.2083 35.1191 81.0252 35.1299C81.0575 34.7421 81.1114 34.322 81.1114 33.945C81.1114 33.7511 81.1114 33.5572 81.1006 33.3741C80.6267 32.5446 79.8726 31.2197 79.668 30.875C79.8726 30.875 80.659 30.875 80.8421 30.875C81.1545 31.4782 81.6608 32.4369 81.8439 32.717Z" fill="#F37830"/>
        <path d="M16.403 10.5913C16.3061 12.8965 16.3061 15.8157 16.3061 18.4332C16.3061 23.2806 16.3707 24.3685 17.2755 25.5642C18.1804 26.7491 19.8823 27.4169 22.069 27.4169C24.6112 27.4169 26.6147 26.1459 27.3149 24.2823C27.5626 23.6145 27.7242 22.6773 27.7242 20.1783C27.7242 16.2142 27.6919 12.2394 27.4442 8.28616C27.8858 8.35079 29.857 8.35079 30.331 8.28616L30.2233 9.72959C30.0725 12.3471 29.9755 18.6164 29.9109 21.6863C29.8463 25.2841 28.7906 26.9214 26.7117 28.2033C25.2359 29.1189 23.1462 29.5067 21.4442 29.5067C17.6849 29.5067 13.1715 28.3541 12.4821 23.636C12.4067 23.2052 12.4067 22.3003 12.4067 22.031V13.7475C12.4067 12.1963 12.342 9.82654 12.2559 8.27539C12.9776 8.37234 15.8537 8.37234 16.4892 8.27539L16.403 10.5913Z" fill="#F37830"/>
        <path d="M35.027 16.6341C35.027 15.2984 35.027 15.0076 34.9408 12.3038C34.8977 11.6683 34.8439 8.93224 34.8008 8.36133C35.5979 8.51213 38.3878 8.47982 39.1634 8.36133C38.9479 12.9394 38.851 16.7526 38.851 21.4168C38.851 23.5389 38.9156 25.5101 38.9479 27.169H39.5188C40.4237 27.169 44.9479 27.169 46.7898 26.8997C46.6821 27.2336 46.6713 28.7416 46.7898 29.1402C45.1848 29.0863 42.4919 28.9679 40.8007 28.9679C38.7864 28.9679 37.0952 29.0217 34.8008 29.151C34.8008 28.677 35.027 21.643 35.027 20.8782V16.6341Z" fill="#F37830"/>
        <path d="M55.5473 24.9937C55.5473 27.2989 55.6658 28.2899 55.7736 29.1409C54.858 29.0547 52.4774 29.0224 51.3463 29.1409C51.4864 26.8465 51.6479 24.509 51.6479 22.2038L51.6264 13.7479C51.6264 12.6276 51.5941 11.572 51.551 10.3871H49.9891C49.709 10.3871 46.7037 10.4517 45.0879 10.5918C45.1848 10.2471 45.2602 8.5451 45.1633 8.17886C47.6408 8.32966 51.0555 8.32967 53.4792 8.32967C56.0752 8.32967 58.8758 8.27581 61.8704 8.125C61.7735 8.65282 61.7412 10.1178 61.8489 10.6025C59.9961 10.4194 57.7663 10.3871 55.6012 10.3871C55.5797 11.4858 55.5473 12.6276 55.5473 13.7802V24.9937Z" fill="#F37830"/>
        <path d="M74.0545 25.8227L72.7404 22.6127C71.3939 22.5588 66.2342 22.5588 64.9954 22.6127C63.9829 24.9394 62.9919 27.4385 62.4856 29.162C62.0762 29.1081 60.0188 29.1081 59.5664 29.162L63.1965 21.3739C66.3634 14.5554 68.1839 10.6237 68.9164 8.27539H70.9522C72.4603 12.2825 74.3131 16.5158 75.4549 19.058C76.6398 21.6863 78.708 26.232 80.1083 29.162C79.6451 29.1404 75.9288 29.1404 75.3687 29.162L74.0545 25.8227ZM68.884 13.3166L65.7064 20.7384C66.8159 20.7707 67.8607 20.8246 68.9487 20.8246C69.5734 20.8246 70.5214 20.8246 71.8355 20.7384L68.884 13.3166Z" fill="#F37830"/>
        <path d="M7.75573 9.21484C2.86531 12.5326 0 16.6905 0 21.7856C0 33.3438 13.9495 41.8751 33.8559 41.8751C42.7642 41.8751 50.2183 39.5699 55.7335 36.7261H55.3888C48.7426 38.7405 41.9994 39.5591 35.0731 39.5591C15.0698 39.5591 1.30339 30.1661 1.28185 21.4947C1.28185 16.6905 3.51162 12.5433 7.75573 9.50568V9.21484Z" fill="#F37830"/>
        <path d="M77.6755 8.25586C78.3434 8.25586 78.9143 8.79445 78.9143 9.50539C78.9143 10.2163 78.3434 10.7657 77.6755 10.7657C76.9969 10.7657 76.4044 10.2271 76.4044 9.50539C76.3937 8.79445 76.9861 8.25586 77.6755 8.25586ZM77.6755 10.5718C78.2464 10.5718 78.6988 10.1086 78.6988 9.49462C78.6988 8.8914 78.2464 8.42821 77.6755 8.42821C77.0723 8.42821 76.6414 8.8914 76.6414 9.49462C76.6414 10.1086 77.0723 10.5718 77.6755 10.5718ZM77.18 8.77291H77.7402C78.0848 8.77291 78.2572 8.91294 78.2572 9.19301C78.2572 9.45153 78.0849 9.57002 77.8694 9.59157L78.2895 10.2379H78.0418L77.654 9.60234H77.3955V10.2379H77.18V8.77291ZM77.3955 9.41922H77.6324C77.8371 9.41922 78.0202 9.40845 78.0202 9.19301C78.0202 8.98834 77.8371 8.9668 77.6863 8.9668H77.3955V9.41922Z" fill="#F37830"/>
    </svg>
    );
}; 

export default Ulta;