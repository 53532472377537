import React, { useState, useEffect } from 'react';
import ConfirmProduct from './ConfirmationProduct'; // Import ConfirmProduct
import { useParams } from 'react-router-dom';
import supabase from './SupabaseClient';
import Plans from './Plans';

const ConfirmedEmail = () => {
    const [session, setSession] = useState(null);
    const [userData] = useState(null);
    const { productId } = useParams();
    const [showPlans, setShowPlans] = useState(false);
    const [requestSent, setRequestSent] = useState(false);

    useEffect(() => {
        const handleAuthChange = async (event, session) => {
            if (session) {
                setSession(session);
            }
        };

        const { data: { subscription } } = supabase.auth.onAuthStateChange(handleAuthChange);

        return () => {
            subscription?.unsubscribe();
        };
    }, []);

    useEffect(() => {
        const sendUserRequest = async () => {
            if (session && !requestSent) {
                const payload = { userID: session.user.id, userEmail: session.user.email };
                try {
                    setRequestSent(true);  // Set this before making the request to prevent multiple sends
                    const response = await fetch('/newUser', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(payload),
                    });
                    console.log('Response:', response);
                } catch (error) {
                    console.error('Error:', error);
                    setRequestSent(false);  // Reset in case of error
                }
            }
        };

        sendUserRequest();
    }, [session, requestSent]);

    return (
        <>
            {!showPlans && productId && <div className='msgBanner'>
                <p className='noMarginP centeredText whiteText'>Thank you <strong>{session && session.user.email}</strong> for confirming your email.</p>
            </div>}
            <div className='pageWrapper'>
                {!productId && <div className='slide vSlide'>
                    <p>Thank you <strong>{session && session.user.email}</strong> for confirming your email.</p>
                    {!productId && <button className='btnBasic btnBig' onClick={() => window.location.href="/"}>Continue</button>}
                </div>}
                <div className='slide vSlide'>
                    {session && productId && !showPlans && <ConfirmProduct productId={productId} userID={session.user.id} trackers={userData ? userData.trackers : 0} setShowPlans={setShowPlans} />}
                    {showPlans && <Plans setShowPlans={setShowPlans} productId={productId} />}
                </div>
            </div>
        </>
    );
};

export default ConfirmedEmail;