import React from 'react';

const Lidl = () => {
  return (
    <svg width="45" height="50" viewBox="0 0 45 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.390625 2.89062H44.6084V47.1084H0.390625V2.89062Z" fill="#0050AA"/>
        <path d="M44.6085 2.8915V47.1093H0.39075V2.8915H44.6085ZM45 2.5H0V47.5H45V2.5Z" fill="white"/>
        <path d="M22.4992 5.38672C11.6677 5.38672 2.88672 14.1677 2.88672 24.9992C2.88672 35.8307 11.6677 44.6117 22.4992 44.6117C33.3277 44.6117 42.1072 35.8352 42.1117 25.0075C42.1117 14.1745 33.3315 5.39122 22.4992 5.38672Z" fill="#FFF000"/>
        <path d="M21.2824 25.5526L17.8196 22.0898L13.8281 26.0888V27.4313L14.8339 26.4218L17.6201 29.2156L16.5911 30.2408L17.2601 30.9136L22.8326 25.3336V23.9956L21.2831 25.5533L21.2824 25.5526Z" fill="#E60A14"/>
        <path d="M5.11719 21.3613H11.2844V22.6918H10.2554V26.9961L13.8277 25.0086V28.6513H5.11719V27.3133H6.14994V22.6918H5.11719V21.3613ZM31.1197 21.3613V22.6918H32.1524V27.3133H31.1197V28.6513H39.8377V25.0086L36.2609 26.9961V22.6918H37.2937V21.3613H31.1197Z" fill="#0050AA"/>
        <path d="M17.3105 17.2168C18.5225 17.2168 19.5057 18.1993 19.5057 19.412C19.5057 20.6248 18.5232 21.6073 17.3105 21.6073C16.0985 21.6073 15.1152 20.6248 15.1152 19.412C15.1152 19.409 15.1152 19.4068 15.1152 19.4038V19.4045C15.1152 18.1963 16.0947 17.2168 17.303 17.2168C17.306 17.2168 17.3075 17.2168 17.3105 17.2168Z" fill="#E60A14"/>
        <path d="M22.4989 4.06641C22.4974 4.06641 22.4966 4.06641 22.4951 4.06641C10.9309 4.06641 1.55664 13.4407 1.55664 25.0049C1.55664 36.5692 10.9309 45.9434 22.4951 45.9434C34.0579 45.9434 43.4314 36.5714 43.4336 25.0087C43.4314 13.4459 34.0601 4.07241 22.4989 4.06641ZM22.4989 44.6174C11.6674 44.6174 2.88639 35.8364 2.88639 25.0049C2.88639 14.1734 11.6674 5.39241 22.4989 5.39241C33.3304 5.39241 42.1114 14.1734 42.1114 25.0049C42.1114 25.0079 42.1114 25.0102 42.1114 25.0132V25.0124C42.1024 35.8379 33.3259 44.6107 22.4996 44.6129L22.4989 44.6174Z" fill="#E60A14"/>
        <path d="M27.6842 21.3613H21.8147V22.6918H22.8437V27.3133H21.8027V28.6513H27.6722C32.0312 28.6513 32.086 21.3613 27.6842 21.3613Z" fill="#0050AA"/>
        <path d="M26.8597 26.3699H26.5664V23.6309H26.8132C28.1009 23.6309 28.1002 26.3699 26.8597 26.3699Z" fill="#FFF000"/>
    </svg>
    );
}; 

export default Lidl;