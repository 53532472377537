import React from 'react';
import './LandingElements.css';
import FAQComponent from './ProductFAQ';
import LogoCarousel from './StoresCarousel';

const LandingElements = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // This will make the scroll smooth
        });
    };

  return (
    <>
        <div className='slide mSlide'>
            <h2 className='redText capText sticky'>Have you ever been in this situation:</h2>
        </div>
        <div className='redBG situ'>
            <h3 className='noMarginP capText'>Yesterday</h3>
            <div className='situInner'>
                <img className='situImg' src="Track_the_prices_of_sneakers.jpeg" alt="Description 1" />
                <img className='situImg' src="Frame_order.svg" alt="Description 1" />
                <img className='situImg' src="Happy--Shock-1.png" alt="Description 1" />
            </div>
        </div>

        <div className='redBG situ marginT'>
            <h3 className='noMarginP capText'>Today</h3>
            <div className='situInner'>
                <img className='situImg' src="Track_the_prices_of_sneakers.jpeg" alt="Description 1" />
                <img className='situImg' src="Frame_new_price.svg" alt="Description 1" />
                <img className='situImg' src="Happy--Shock-2.png" alt="Description 1" />
            </div>
        </div>
        <div className="parent-container">
            <div className='mSlide'>
                <div className='slideElement'>
                    <h2 className={`redText capText lightText ${window.innerWidth < 768 ? 'noMarginP' : ''}`}>
                        Frustrating, right?
                    </h2>
                </div>
                <div className='slideElement nd'>
                    <h2 className={`redText capText ${window.innerWidth < 768 ? 'noMarginP' : ''}`}>
                        We thought so, and that’s why we created Thrill!
                    </h2>
                </div>
            </div>        
            <div className="flex-container">
                <div className="contentElement">
                    <h3>
                        Track Prices, Buy at The Lowest, Save Money!
                    </h3>
                    <p> 
                        Thrill constantly monitors the prices of your favorite items and sends you an email notification whenever they change. Because why settle for the highest price?                    
                    </p>
                </div>
                <div className="contentElement fEnd">
                    <img className="contentElement" src="Track_Prices_Save_Money_Shop_Smarter.svg" alt="Description 1" />
                </div>
            </div>
        </div>
        <div className='mSlide'>
            <div className='slideElement'>
                <h2 className={`redText capText lightText ${window.innerWidth < 768 ? 'noMarginP' : ''}`}>  
                    50+ webstores
                </h2>
            </div>
            <div className='slideElement nd'>
                <h2 className={`redText capText ${window.innerWidth < 768 ? 'noMarginP' : ''}`}>
                    & new stores added daily.   
                </h2>
            </div>
            <div className='slideElement rd'>
                <LogoCarousel />        
            </div>
        </div>
        <div>
            <div className='slideElement'>
                <h2 className={`redText capText lightText ${window.innerWidth < 768 ? 'noMarginP' : ''}`}>
                    Gotcha!
                </h2>
            </div>
            <div className='slideElement nd'>
                <h2 className={`redText capText ${window.innerWidth < 768 ? 'noMarginP' : ''}`}>
                    But how does it work?
                </h2>
            </div>
        </div>

        <div className="parent-container ">
            <div className='slide'>
                <div className="flex-container">
                    <div className="contentElement">
                        <h3> 
                            1. Paste Your Item’s Link
                        </h3>
                        <p>
                            Copy the URL of the item you want to track from your favorite online store and paste it into the designated field on our site.
                        </p>
                    </div>
                    <div className="contentElement fEnd">
                        <img className="contentElement" src="Price_Tracker_Add_A_Link.svg" alt="Description 1" />
                    </div>
                </div>
            </div>            
            {/* Second flex container with image on the left */}
            <div className='slide'>
                <div className="flex-container reverse">
                    <div className="contentElement">
                        <img className="contentElement" src="Price_Tracker_Confirm_Information.svg" alt="Description 2" />
                    </div>
                    <div className="contentElement">
                        <h3>
                            2. Confirm Item's Details
                        </h3>
                        <p>
                            Ensure the information provided matches your desired item accurately.
                        </p>
                    </div>
                </div>
            </div>
            
            <div className="slide">
                <div className="flex-container">
                    <div className="contentElement">
                        <h3>
                            3. Get Notified!
                        </h3>
                        <p>
                            That’s it! We’ll ping your inbox every time there’s a price twist. Happy shopping!
                        </p>
                    </div>
                    <div className="contentElement fEnd">
                        <img className="contentElement" src="Price_Tracker_Get_Notified.svg" alt="Description 3" />
                    </div>
                </div>
            </div>
            <div className='marginT'>
                <FAQComponent />
            </div>
            <div className="footerHero marginT">
                <h2 className='redText capText noMarginP marginBB'>Ready to save money on your favorite items?</h2>
                <button className='btnBasic btnBig btnBlack' onClick={scrollToTop}>
                    Yeah, Let's do it!
                </button>
            </div>
        </div>

    </>
  );
};

export default LandingElements;
