import React, { useState, useEffect } from 'react';

export const fetchUserData = async (userID, setUserData, setError) => {
  setError(null);

  try {
    const response = await fetch(`/getUser?id=${userID}`);
    if (response.ok) {
      const data = await response.json();
      setUserData(data);
    } else {
      setError('Failed to fetch user data');
    }
  } catch (error) {
    setError('Error fetching user data');
  } 
};

function UserData({ userID, setUserData }) {
  const [error, setError] = useState(null);

  useEffect(() => {
    if (userID) {
      fetchUserData(userID, setUserData, setError);
    }
  }, [userID, setUserData]);

  if (error) {
    return <p>{error}</p>;
  }

  return null;  
}

export default UserData;
