import React, { useEffect } from 'react';
import Walmart from './icons/Walmart';
import Target from './icons/Target';
import Amazon from './icons/Amazon';
import Nike from './icons/Nike';
import Ulta from './icons/Ulta';
import Asos from './icons/Asos';
import Argos from './icons/Argos';
import BBB from './icons/BedBathBeyond';
import BestBuy from './icons/BestBuy';
import Bol from './icons/Bol';
import Dermstore from './icons/Dermstore';
import Next from './icons/Next';
import Gap from './icons/Gap';
import Lidl from './icons/Lidl';
import Ikea from './icons/Ikea';
import Jcrew from './icons/Jcrew';
import Kohls from './icons/Kohls';
import Macys from './icons/Macys';
import MandS from './icons/MandS';
import Microcenter from './icons/Microcenter';
import Oldnavy from './icons/Oldnavy';
import Samsclub from './icons/Samsclub';
import Spartoo from './icons/Spartoo';
import Shopbop from './icons/Shopbop';
import Zalando from './icons/Zalando';
import Zappos from './icons/Zappos';
import Otto from './icons/Otto';
import BananaRepublic from './icons/BananaRepublic';
import Poshmark from './icons/Poshmark';
import Reformation from './icons/Reformation';
import Ebay from './icons/Ebay';

const logos = [
  <Walmart />, <Target />, <Amazon />, <Nike />, <Ulta />,
  <Asos />, <Argos />, <BBB />, <BestBuy />, <Bol />,
  <Dermstore />, <Next />, <Gap />, <Lidl />, <Ikea />,
  <Jcrew />, <Kohls />, <Macys />, <MandS />, <Microcenter />,
  <Oldnavy />, <Samsclub />, <Spartoo />, <Shopbop />, <Zalando />,
  <Zappos />, <Otto />, <BananaRepublic />, <Poshmark />, <Reformation />,
  <Ebay />
];

const LogoCarousel = () => {
useEffect(() => {
    const styleSheet = document.styleSheets[0];
    const keyframes = `
        @keyframes scroll {
        0% { transform: translateX(0); }
        100% { transform: translateX(-100%); }
        }
    `;
    styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
    }, []);

  return (
    <div style={styles.carouselContainer}>
      <div style={styles.carouselContent}>
        {logos.concat(logos).map((Logo, index) => ( // Duplicate the logos to create a seamless loop
          <div key={index} style={styles.logoWrapper}>
            {Logo}
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  carouselContainer: {
    overflow: 'hidden',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  carouselContent: {
    display: 'flex',
    animation: 'scroll 30s linear infinite', // Control speed of the scrolling
    width: '200%', // Ensure there's enough space for the scrolling logos
  },
  logoWrapper: {
    flex: '0 0 auto',
    margin: '0 40px',
  }
};

export default LogoCarousel;
