import React from 'react';

const MainLogo = () => {
  const handleLogoClick = () => {
    window.location.href = '/'; 
    window.location.reload(); 
  };

  return (
    <a className='mainLogo' href="/" onClick={handleLogoClick}>      
      <svg width="107" height="40" viewBox="0 0 107 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.77643 25.0509V4.1133H0V0H13.0937V4.1133H9.3482V25.0509H3.77643Z" fill="black"/>
        <path d="M15.394 25.0509V0H20.9348V10.1441H24.8041V0H30.3759V25.0509H24.8041V14.1027H20.9348V25.0509H15.394Z" fill="black"/>
        <path d="M34.1941 25.0509V0H41.0969C42.7891 0 44.2543 0.185562 45.4924 0.556687C46.7306 0.927811 47.6902 1.5979 48.3712 2.56694C49.0728 3.51537 49.4236 4.86585 49.4236 6.61838C49.4236 7.64929 49.3411 8.56679 49.176 9.37089C49.0109 10.175 48.7014 10.876 48.2474 11.4739C47.814 12.0512 47.1846 12.5358 46.3592 12.9275L49.7951 25.0509H44.0685L41.3136 13.8244H39.7349V25.0509H34.1941ZM39.7349 10.6389H41.2827C42.0668 10.6389 42.6756 10.5049 43.109 10.2368C43.5423 9.94819 43.8415 9.54614 44.0066 9.03069C44.1924 8.49462 44.2852 7.85547 44.2852 7.11322C44.2852 6.04108 44.0892 5.20605 43.6971 4.60813C43.305 4.0102 42.5827 3.71124 41.5303 3.71124H39.7349V10.6389Z" fill="black"/>
        <path d="M81.0073 25.0509V0H86.5481V21.3087H92.3056V25.0509H81.0073Z" fill="black"/>
        <path d="M94.7312 25.0509V0H100.272V21.3087H106.03V25.0509H94.7312Z" fill="black"/>
        <path d="M68.136 29.4531L73.2641 24.3249L77.1821 28.2429L65.3976 39.9986L65.3981 39.9658L65.3976 39.9991L53.6113 28.2457L57.5293 24.3277L62.5859 29.3843V14.9961H68.136V29.4531Z" fill="#FF3D00"/>
      </svg>
    </a>
  );
}; 

export default MainLogo;