import React from 'react';

const MandS = () => {
  return (
    <svg width="63" height="50" viewBox="0 0 63 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.8462 6.54699V29.5407H18.733V10.7619L11.1713 25.4514H9.68636L2.11318 10.7962V29.5407H0V6.54699H2.20456L10.4402 22.4244L18.6759 6.54699H20.8462ZM43.2231 29.8719L39.3965 25.7826C38.0144 27.9529 36.1296 29.5407 33.0341 29.5407C29.0476 29.5407 26.112 26.7764 26.112 23.3839C26.112 20.5511 27.8597 18.8034 31.2179 16.8959C29.3104 14.9883 28.2481 13.4005 28.2481 11.5272C28.2481 8.79724 30.5554 6.61552 33.354 6.61552C36.3238 6.61552 38.5284 8.78581 38.5284 11.5272C38.5284 14.1659 36.4495 15.9364 34.382 17.2956L39.1224 22.4701L41.9209 17.9239L43.6 18.9748L40.5045 24.0121L44.7537 28.524L43.2231 29.8719ZM32.543 18.3351C29.5502 19.98 28.2252 21.305 28.2252 23.3382C28.2252 25.6798 30.3384 27.6217 33.0341 27.6217C35.4671 27.6217 36.7236 26.5023 38.0715 24.332L32.543 18.3351ZM30.3384 11.5158C30.3384 12.7951 31.1951 13.8917 33.0684 15.9021C35.273 14.6113 36.4952 13.2635 36.4952 11.5158C36.4952 9.80242 35.3415 8.45456 33.3654 8.45456C31.5263 8.45456 30.3384 9.90523 30.3384 11.5158ZM62.5844 11.4587L60.6768 12.1555C59.9229 9.74531 57.7755 8.19184 54.8741 8.19184C51.7101 8.19184 49.6426 9.76816 49.6426 12.441C49.6426 18.1752 62.7557 15.1368 62.7557 23.2125C62.7557 27.062 59.6602 29.7691 55.0455 29.7691C50.762 29.7691 48.1919 27.7245 46.4214 24.469L48.2033 23.3839C49.3913 25.8854 51.5616 27.8273 54.8627 27.8273C58.6778 27.8273 60.6654 26.0796 60.6654 23.1783C60.6654 17.4099 47.5522 20.2427 47.5522 12.4068C47.5522 7.75778 51.7329 6.25 54.8284 6.25C58.3009 6.25 61.465 7.82632 62.5844 11.4587Z" fill="#1D1D1B"/>
        <path d="M15.1009 41.5242H17.3397V40.9302H15.1009V39.4452H17.4996V38.8398H14.4727V43.6488H17.6367V43.0548H15.1009V41.5242Z" fill="#1D1D1B"/>
        <path d="M20.8567 40.9064L20.8338 40.895C20.1485 40.7008 19.7715 40.5638 19.7715 40.1183C19.7715 39.6728 20.1485 39.3872 20.731 39.3872C21.2108 39.3872 21.5763 39.5814 21.7362 39.9469L21.8047 40.0954L22.3188 39.7985L22.2731 39.6842C21.9304 38.8618 21.028 38.8047 20.7539 38.8047C20.1599 38.8047 19.1661 39.0903 19.1661 40.1411C19.1661 41.0664 19.9886 41.3062 20.7196 41.5233C21.4392 41.7289 21.839 41.8774 21.839 42.3457C21.839 42.8369 21.4164 43.1567 20.7539 43.1567C20.1485 43.1567 19.703 42.894 19.4631 42.3914L19.3946 42.2429L18.8691 42.5513L18.9263 42.6769C19.2689 43.3623 19.9086 43.7507 20.731 43.7507C21.7362 43.7507 22.4444 43.1567 22.4444 42.3114C22.433 41.3633 21.5649 41.112 20.8567 40.9064Z" fill="#1D1D1B"/>
        <path d="M23.5293 39.4452H24.9914V43.6488H25.6196V39.4452H27.0817V38.8398H23.5293V39.4452Z" fill="#1D1D1B"/>
        <path d="M28.7276 42.9043C28.5106 42.9043 28.3164 43.0871 28.3164 43.3041C28.3164 43.5211 28.4992 43.7153 28.7276 43.7153C28.9446 43.7153 29.1388 43.5325 29.1388 43.3041C29.1274 43.0871 28.9446 42.9043 28.7276 42.9043Z" fill="#1D1D1B"/>
        <path d="M32.3145 39.6386H33.0341V43.6479H33.6623V39.0332H32.3259L32.3145 39.6386Z" fill="#1D1D1B"/>
        <path d="M47.493 39.0332H47.1389L44.8887 42.1516V42.5742H47.2303V43.6594H47.8357V42.5742H48.4297V42.0488L47.8357 41.9917V39.0332H47.5159M45.7339 41.9688L47.2417 39.8899V41.9688H45.7339Z" fill="#1D1D1B"/>
        <path d="M38.0041 41.2128C38.4039 40.9958 38.6438 40.6646 38.6438 40.1962C38.6438 39.3624 37.9698 38.9512 37.1588 38.9512C36.3364 38.9512 35.6739 39.3738 35.6739 40.1962C35.6739 40.6646 35.9023 40.9958 36.3021 41.2128C35.8795 41.407 35.5254 41.7611 35.5254 42.298C35.5254 43.1204 36.1194 43.6801 37.1588 43.6801C38.1983 43.6801 38.7923 43.0976 38.7923 42.298C38.7808 41.7611 38.4267 41.4185 38.0041 41.2128ZM36.245 40.2305C36.245 39.7393 36.5648 39.4538 37.1588 39.4538C37.7414 39.4538 38.0726 39.7393 38.0726 40.2305C38.0726 40.7217 37.6157 40.973 37.1588 40.973C36.6562 40.973 36.245 40.6874 36.245 40.2305ZM37.1474 43.189C36.5648 43.189 36.1194 42.9034 36.1194 42.3094C36.1194 41.7497 36.6105 41.4756 37.1474 41.4756C37.6843 41.4756 38.1754 41.7497 38.1754 42.3094C38.1754 42.9034 37.73 43.189 37.1474 43.189Z" fill="#1D1D1B"/>
        <path d="M42.592 41.2128C42.9918 40.9958 43.2316 40.6646 43.2316 40.1962C43.2316 39.3624 42.5577 38.9512 41.7467 38.9512C40.9243 38.9512 40.2618 39.3738 40.2618 40.1962C40.2618 40.6646 40.4902 40.9958 40.89 41.2128C40.4674 41.407 40.1133 41.7611 40.1133 42.298C40.1133 43.1204 40.7073 43.6801 41.7467 43.6801C42.7862 43.6801 43.3801 43.0976 43.3801 42.298C43.3687 41.7611 43.026 41.4185 42.592 41.2128ZM40.8329 40.2305C40.8329 39.7393 41.1642 39.4538 41.7467 39.4538C42.3293 39.4538 42.6605 39.7393 42.6605 40.2305C42.6605 40.7217 42.2036 40.973 41.7467 40.973C41.2441 40.973 40.8329 40.6874 40.8329 40.2305ZM41.7353 43.189C41.1527 43.189 40.7073 42.9034 40.7073 42.3094C40.7073 41.7497 41.1984 41.4756 41.7353 41.4756C42.2721 41.4756 42.7633 41.7497 42.7633 42.3094C42.7747 42.9034 42.3178 43.189 41.7353 43.189Z" fill="#1D1D1B"/>
    </svg>

    );
}; 

export default MandS;