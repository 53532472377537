import React, { useState } from 'react';
import Supabase from './SupabaseClient'; 

const UpdatePassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const supabase = Supabase();

  const handleUpdatePassword = async () => {
    if (!supabase) return; // Wait for Supabase to be initialized

    try {
      if (password !== confirmPassword) {
        setError('Passwords do not match');
        return;
      }

      const { error } = await supabase.auth.updateUser({
        password
      });

      if (error) {
        setError(error.message);
      } else {
        setMessage('Password updated, we are redirecting you to your account');
        setTimeout(() => {
          window.location.href = '/signin';
        }, 5000); // 15 seconds
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className='slide'>
      {!message && (
      <div className='formElements'>
        <h3 className='noMarginP centeredText capText'>Add Your Password</h3>
        <input className='mainInput inputSmall'
          type="password"
          id="passwordInput"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter Your New Password"
        />
        <input className='mainInput inputSmall'
          type="password"
          id="confirmPasswordInput"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm Your New Password"
        />
        <button className='btnBasic' onClick={handleUpdatePassword}>Update Password</button>
      </div>
      )}
      {error && <div>{error}</div>}
      {message && <p>{message}</p>}
    </div>
  );
};

export default UpdatePassword;
