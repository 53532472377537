import React, { useState, useEffect } from 'react';
import supabase from './SupabaseClient';
import UserData, { fetchUserData } from './UserData';
import ProductForm from './ProductForm';
import ProductsList from './ProductsList';
import ProductData from './ProductDetails';
import Plans from './Plans';
import MainLogo from './icons/MainLogo';
import ProfileTrigger from './ProfileTrigger';
import LandingElements from './LandingElements';
import Footer from './Footer';

const WelcomePage = () => {
  const [session, setSession] = useState(null);
  const [userData, setUserData] = useState(null);
  const [productData, setProductData] = useState(null);
  const [resetForm, setResetForm] = useState(null);
  const [showPlans, setShowPlans] = useState(false);
  const [activateProduct, setactivateProduct] = useState(false);
  const [activeProductID, setActiveProductID] = useState(null);

  useEffect(() => {
      const { data: { subscription } } = supabase.auth.onAuthStateChange(
        async (event, session) => {
          setSession(session);
        }
      );

      return () => {
        subscription?.unsubscribe();
      };
  }, []);

  const handleLogout = async () => {
      await supabase.auth.signOut();
      setShowPlans(false);
  };

  const refreshProducts = () => {
      setProductData(prevData => !prevData);  
  };

  const refreshUsers = () => {
      if (session && session.user) {
          fetchUserData(session.user.id, setUserData, () => {});  // No-ops for loading and error
      }
  };
  const isProductDataVisible = productData && Object.keys(productData).length > 0;


  return (
    <div>
      <nav>
          <MainLogo />
          {session ? (
              <>
                  <div className='centeredFlex'>
                      <UserData userID={session.user.id} setUserData={setUserData} /> 
                      <p className='noMarginP'>Trackers: {userData ? userData.trackers : 0}</p>
                      <button className='btnBasic btnBig' onClick={() => setShowPlans(true)}>Get more</button>
                      <ProfileTrigger email={session.user.email} handleLogout={handleLogout} />
                  </div>
              </>
          ) : (
              <>
                  <button className='btnBasic btnBig' onClick={() => window.location.href="/signup"}>Join for free</button>
              </>
          )}
      </nav>

      {!session && !isProductDataVisible && 
        <div className='formWrapper' id="trackersForm">
          <h1 className='centeredText redText capText'>Get Alerts When The Price On Your Favorite Items Drops</h1>
        </div>
      }

      {!showPlans && !isProductDataVisible && <ProductForm session={session} setProductData={setProductData} setResetForm={setResetForm} isProductDataVisible={isProductDataVisible} />}
      {!showPlans && isProductDataVisible && (
          <ProductData
              productId={productData.productId}
              userID={session && session.user ? session.user.id : null}
              trackers={userData ? userData.trackers : 0}
              resetForm={resetForm}
              setProductData={setProductData}
              setShowPlans={setShowPlans}
              refreshUsers={refreshUsers}
          />
      )}
      {showPlans && (
          <Plans setShowPlans={setShowPlans} {...(productData?.productId ? { productId: productData.productId } : {})} {...(activateProduct && { activateProduct: true })} {...(activeProductID ? { activeProductID } : {})} refreshProducts={refreshProducts} setactivateProduct={setactivateProduct} setActiveProductID={setActiveProductID}/>
      )}
      
      {session && !showPlans && !isProductDataVisible && <ProductsList userID={session.user.id} productData={productData} refreshProducts={refreshProducts} setShowPlans={setShowPlans} trackers={userData ? userData.trackers : 0} setactivateProduct={setactivateProduct} setActiveProductID={setActiveProductID} refreshUsers={refreshUsers} />}
            
      {!session && !isProductDataVisible && <LandingElements/>}
      
      <Footer />

    </div>
  );
};

export default WelcomePage;
