import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import SignUp from './components/SignUp';
import SignIn from './components/SignIn';
import supabase from './components/SupabaseClient';
import WelcomePage from './components/Home';
import UpdatePassword from './components/UpdatePW';
import ConfirmedEmail from './components/ConfirmedEmail';
import Plans from './components/Plans';
import Dashboard from './components/Dashboard'
import PaymentAccepted from './components/PaymentAccepted';
import TermsOfService from './components/Terms';
import PrivacyPolicy from './components/Privacy';
import LogoGallery from './components/StoresList';
import SupportPage from './components/Support';

function App() {
 const [session, setSession] = useState(null);
 const [language, setLanguage] = useState('en');

 useEffect(() => {
  const { data: { subscription } } = supabase.auth.onAuthStateChange(
    async (event, session) => {
      setSession(session);
    }
  );

  return () => {
    subscription?.unsubscribe();
  };
}, []);

 useEffect(() => {
  const userLang = navigator.language || navigator.userLanguage;
  if (userLang.startsWith('fr')) {
    setLanguage('fr');
  } else {
    setLanguage('en');
  }
}, []);

 const userID = session?.user?.id;
 
 return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<WelcomePage userID={userID} /> } />
        <Route path="/plans" element={!session ? <Plans /> : <Navigate to="/Dashboard" />}  />
        <Route path="/signin" element={!session ? <SignIn language={language}/> : <Navigate to="/" />} />
        <Route path="/signup/:productId?" element={!session ? <SignUp /> : <Navigate to="/" />}/>
        <Route path="/dashboard" element={<Dashboard />} /> 
        <Route path="/updatepassword" element={<UpdatePassword />} />
        <Route path="/confirmedemail/:productId?" element={<ConfirmedEmail />} />
        <Route path="/payment_accepted/:productId?" element={<PaymentAccepted />} />
        <Route path="/success" element={<Dashboard />} />
        <Route path="/cancel" element={<Dashboard />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/stores" element={<LogoGallery />} />
        <Route path="/support" element={<SupportPage />} />
      </Routes>
    </BrowserRouter>
 );
}

export default App;