import React from 'react';

const Ebay = () => {
  return (
    <svg width="95" height="50" viewBox="0 0 95 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.7948 23.8148C18.5872 19.3872 15.3904 17.7668 11.9844 17.7861C8.31457 17.8062 5.39165 19.6836 4.89858 23.8935L18.7948 23.8148ZM4.81216 27.0333C5.09303 31.3181 8.05916 33.8344 12.1241 33.812C14.939 33.7958 17.4368 32.6361 18.2601 30.1368L23.126 30.1098C22.21 35.1778 16.8364 36.9263 12.2113 36.951C3.79129 36.9981 0.0350115 32.3845 0.000288297 26.1297C-0.0382931 19.2414 3.79438 14.6927 12.165 14.6448C18.8226 14.6078 23.7224 18.0654 23.7649 25.6722L23.7726 26.926L4.81216 27.0333Z" fill="#E53238"/>
        <path d="M35.915 33.5918C40.3041 33.5671 43.2787 30.3911 43.2517 25.6286C43.2247 20.8662 40.2061 17.7226 35.8271 17.7472C31.4481 17.7719 28.4634 20.948 28.4897 25.7104C28.5159 30.4729 31.5561 33.6158 35.915 33.5918ZM23.6523 6.27624L28.3716 6.25L28.4372 18.1091C30.7351 15.3405 33.9158 14.5211 37.054 14.5026C42.3142 14.474 48.1747 17.9888 48.2179 25.6557C48.2526 32.0524 43.6336 36.7725 37.0895 36.8088C33.6457 36.8288 30.4349 35.6166 28.4333 33.1968C28.4385 34.1584 28.3895 35.1196 28.2867 36.0757L23.6616 36.1019C23.731 34.6243 23.8044 32.7346 23.7959 31.2237L23.6523 6.27624Z" fill="#0064D2"/>
        <path d="M59.6348 26.1025C55.3539 26.2692 52.7018 27.0509 52.7172 29.8704C52.7272 31.6953 54.2142 33.666 57.8871 33.6444C62.8371 33.6174 65.4691 30.9005 65.4444 26.4768V25.9852C63.7067 26.0007 61.5562 26.0269 59.6348 26.1025ZM70.193 31.8944C70.2007 33.2663 70.2478 34.6244 70.372 35.8297L65.983 35.8543C65.8687 34.8747 65.8107 33.8892 65.8094 32.9029C63.4544 35.8351 60.6333 36.6908 56.7173 36.7132C50.9054 36.7456 47.778 33.6922 47.758 30.1404C47.7294 24.9976 51.951 23.158 59.2969 22.9504C61.3031 22.8949 63.5632 22.8733 65.4275 22.8609V22.3694C65.4074 18.9256 63.1897 17.5197 59.3671 17.5413C56.5275 17.5575 54.4534 18.7458 54.2335 20.7822L49.2688 20.8092C49.7665 15.7427 55.0792 14.4286 59.7591 14.4047C65.3634 14.3738 70.1035 16.3399 70.1359 22.2691L70.193 31.8944Z" fill="#F5AF02"/>
        <path d="M70.945 21.5724L67.4727 15.1201L72.9929 15.0892L81.1636 31.2332L89.133 15.0136L94.1378 14.9766L79.7029 43.7204L74.4173 43.7505L78.5702 35.7657L70.9411 21.5732" fill="#86B817"/>
    </svg>
    );
}; 

export default Ebay;