import React from 'react';

const Bol = () => {
  return (
    <svg width="77" height="50" viewBox="0 0 77 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 10H8.75598V17.2499C10.4636 16.6072 12.2746 16.2833 14.0991 16.2944C20.9762 16.2944 25.9616 21.3311 25.9616 28.234C25.9616 33.4443 22.3945 39.6101 14.2705 39.6101H0V10ZM8.75598 32.0046C9.84867 32.1998 10.956 32.3016 12.066 32.3088C15.2046 32.3088 17.2677 30.6163 17.2677 27.9662C17.2677 25.3631 15.2474 23.5828 12.3231 23.5828C11.1144 23.5723 9.91127 23.7479 8.75598 24.1034V32.0046ZM40.6777 16.2879C50.3485 16.2879 54.0441 22.5844 54.0441 28.1418C54.0441 34.2198 49.7893 39.9936 40.7613 39.9936C31.0905 39.9936 27.307 33.7421 27.307 28.1418C27.3156 22.2352 31.3133 16.2879 40.6841 16.2879H40.6777ZM40.6777 32.5702C43.4114 32.5702 45.2924 30.7448 45.2924 28.1418C45.2924 25.5388 43.4178 23.7113 40.6841 23.7113C37.9504 23.7113 36.0694 25.5367 36.0694 28.1418C36.0694 30.747 37.9504 32.5702 40.6841 32.5702H40.6777ZM55.8266 10H64.5697V39.6037H55.8266V10ZM76.0209 34.659C76.0209 37.6112 73.7349 40 70.8577 40C67.9804 40 65.6495 37.6112 65.6495 34.659C65.6413 33.9686 65.7698 33.2835 66.0275 32.6429C66.2853 32.0024 66.6672 31.4192 67.1513 30.927C67.6354 30.4347 68.2121 30.0431 68.8482 29.7747C69.4843 29.5062 70.1673 29.3663 70.8577 29.363C73.7349 29.3566 76.0209 31.7004 76.0209 34.6526V34.659Z" fill="#0000A4"/>
    </svg>
    );
}; 

export default Bol;