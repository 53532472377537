import React from 'react';

const Nike = () => {
  return (
    <svg width="97" height="50" viewBox="0 0 97 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M96.7227 8.125L25.9879 38.1298C20.0987 40.6284 15.1445 41.875 11.1521 41.875C6.66 41.875 3.38764 40.2899 1.378 37.125C-1.22807 33.0412 -0.08892 26.475 4.38171 19.5434C7.03614 15.4919 10.4106 11.7735 13.6991 8.21635C12.9253 9.47371 6.0958 20.8383 13.5648 26.1902C15.0424 27.2649 17.1434 27.7915 19.728 27.7915C21.8021 27.7915 24.1825 27.4529 26.7993 26.7705L96.7227 8.125Z" fill="black"/>
    </svg>
    );
}; 

export default Nike;