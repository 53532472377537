import React from 'react';

const Otto = () => {
  return (
    <svg width="78" height="50" viewBox="0 0 78 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 11.875V38.125H77.7935V11.875" fill="white"/>
        <g mask="url(#mask0_3493_1219)">
        <path d="M70.4406 23.3059L70.3697 24.1528C69.8757 30.2213 67.9002 32.0915 65.4657 32.0915C63.2777 32.0915 61.725 30.6097 62.0428 26.6581L62.1134 25.7755C62.6077 19.742 64.583 17.8367 67.0175 17.8367C69.1703 17.8367 70.7581 19.3544 70.4406 23.3059ZM15.7506 23.3059L15.6796 24.1528C15.1863 30.2213 13.21 32.0915 10.7755 32.0915C8.58765 32.0915 7.03566 30.6097 7.35314 26.6581L7.42317 25.7755C7.91761 19.742 9.89354 17.8367 12.3282 17.8367C14.4802 17.8367 16.068 19.3544 15.7506 23.3059ZM67.7234 11.874C63.8473 11.874 60.6943 13.0689 58.4666 15.5425C58.7839 14.6838 58.9512 13.6366 58.973 12.4032H45.3889C42.4251 12.4032 40.7314 13.7085 39.5671 17.0606L40.1671 12.4032H26.4414C23.9286 12.4032 22.2034 13.6047 21.5801 15.9577C19.8792 13.2318 16.857 11.874 13.0335 11.874C5.76546 11.874 1.03722 16.073 0.190368 25.0346L0.0844932 26.1638C-0.656483 34.1736 3.54253 38.125 10.0696 38.125C17.3382 38.125 22.0666 33.891 22.9133 24.9287L23.0192 23.8001C23.2102 21.7448 23.0707 19.9588 22.6501 18.4367H28.8053L27.0416 33.5385C26.6883 36.5373 28.3118 37.9134 31.0281 37.9134C32.6867 37.9134 33.251 37.8075 33.8861 37.7018L36.074 18.4367H43.2367L41.4724 33.5385C41.1197 36.5373 42.7426 37.9134 45.4597 37.9134C47.1175 37.9134 47.6826 37.8075 48.3177 37.7018L50.5048 18.4367H54.0336C55.2544 18.4367 56.2402 18.153 57.0012 17.5921C55.8902 19.5497 55.1651 22.0218 54.8803 25.0346L54.7746 26.1638C54.0336 34.1736 58.232 38.125 64.7595 38.125C72.0285 38.125 76.7558 33.891 77.6028 24.9287L77.7087 23.8001C78.4498 15.8255 74.2513 11.874 67.7234 11.874Z" fill="#DB1F26"/>
        </g>
    </svg>
    );
}; 

export default Otto;