import React, { useState, useEffect } from "react";
import MainLogo from './icons/MainLogo';
import UserData from './UserData';
import ProfileTrigger from './ProfileTrigger';
import supabase from './SupabaseClient';

const NavBar = () => {
    const [session, setSession] = useState(null);
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const { data: { subscription } } = supabase.auth.onAuthStateChange(
            async (event, session) => {
                setSession(session);
            }
        );
    
        return () => {
            subscription?.unsubscribe();
        };
    }, []);
    
    const handleLogout = async () => {
        await supabase.auth.signOut();
    };
    

  return (
    <nav>
      <MainLogo />
      {session ? (
        <>
            <div className='centeredFlex'>
                <UserData userID={session.user.id} setUserData={setUserData} /> 
                <p className='noMarginP'>Trackers: {userData ? userData.trackers : 0}</p>
                <ProfileTrigger email={session.user.email} handleLogout={handleLogout} />
            </div>
        </>
        ) : (
        <>
            <button className='btnBasic btnBig' onClick={() => window.location.href="/signup"}>Join for free</button>
        </>
        )}
    </nav>
  );
};

export default NavBar;