import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import clockGif from './icons/search_4.apng';

const ProductData = ({ productId, userID, trackers, resetForm, setProductData, setShowPlans, refreshUsers }) => {
  const [productData, setProductDataState] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      const fetchProductData = async () => {
        try {
          setLoading(true);
          const maxRetries = 6;
          let retries = 0;
          let dataFound = false;

          while (retries < maxRetries && !dataFound) {
            const response = await fetch(`/getProduct?id=${productId}`);
            const data = await response.json();

            if (data.product) {
              setProductDataState(data.product);
              setLoading(false);
              dataFound = true;
            } else {
              retries++;
              await new Promise(resolve => setTimeout(resolve, 20000));
            }
          }

          if (!dataFound) {
            setLoading(false);
          }
        } catch (error) {
          console.error('Error fetching product data:', error);
          setLoading(false);
        }
      };

      fetchProductData();
    }, 20000); 
    return () => clearTimeout(timer);
  }, [productId]);

  const handleConfirm = async () => {
    if (!userID) {
      navigate(`/signup/${productId}`);
    } else if (trackers === 0) {
      setShowPlans(true);
    } else {
      try {
        const payload = { productId, userID, priceText: productData.priceText };
        await fetch('/confirmProduct', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        });
        setProductDataState(null);
        setProductData(null);
        resetForm();
        refreshUsers();
      } catch (error) {
        console.error('Error confirming product:', error);
      }
    }
  };

  const handleCancel = async () => {
    if (!userID) {
      try {
        const response = await fetch(`/OutDelete?id=${productId}`, {
          method: 'DELETE'
        });
        console.log(response);
        setProductDataState(null);
        setProductData(null);
        resetForm();
      } catch (error) {
        console.error('Error deleting product:', error);
      }
    } else {
      try {
        const response = await fetch(`/delete?id=${productId}&userID=${userID}`, {
          method: 'DELETE'
        });
        console.log(response);
        setProductDataState(null);
        setProductData(null);
        resetForm();
      } catch (error) {
        console.error('Error deleting product:', error);
      }
    } 
  };

  return (
    <div>
      {loading ? (
        <>
          <div className='loadingScreen'>
            <img src={clockGif} className='loadingImg' alt="Loading..." />
            <p className='bigP noMarginP centeredText'><strong>We are looking for the information about your item...</strong></p>
            <p className='noMarginP centeredText'>This operation might take up to 1 minute</p>
          </div>
        </>
      ) : !productData ? (
        <>
          <div className='loadingScreen'>
            <h2 className='noMarginP'>🫤</h2>
            <p className='centeredText bigP noMarginP'><strong>Oops! We couldn’t find the item you’re looking for</strong></p> 
            <p className='centeredText noMarginP'>Please double-check the link and try again!</p>
            <button className='btnBasic' onClick={() => window.location.href="/"}>Go back</button>
          </div>
        </>
      ) : (
        <>
          <div className='prodPreview'>
            <h3>Is this the item you want to track?</h3>
            <div className='hoFlex'>
              <img className='productImg' src={productData.productImg} alt={productData.productTitle} />
              <div className='fillContainer'>
                <p className='bigP noMarginP'><strong>{productData.productTitle}</strong></p>
                <p className='smallP capText'><strong>Store: {productData.productCategory}</strong></p>
                <h3 className='redText'>{productData.productPrice}</h3>
                <div className='hFlexFit clmnG endPos'>
                  <button className='btnBasic' onClick={handleConfirm}>Yes, let's track it!</button>
                  <button className='btnBasic greyBtn' onClick={handleCancel}>No, it's the wrong item</button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ProductData;