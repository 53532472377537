import React, { useState, useEffect } from 'react';
import supabase from './SupabaseClient';
import './Plans.css'; 
import NavBar from './NavBar';
import FAQComponent from './ProductFAQ';
import Footer from './Footer';

const Plans = ({ setShowPlans, productId, activateProduct, activeProductID, setactivateProduct, setActiveProductID }) => {
    const [session, setSession] = useState(null);
    const [productData, setProductData] = useState(null);
    
    useEffect(() => {
        const fetchProductData = async () => {
            if (productId) {
                const response = await fetch(`/getProduct?id=${productId}`);
                const data = await response.json();
                if (data.product) {
                    setProductData(data.product);
                } else {
                    console.log("No product found");
                }
            }
        };

        fetchProductData();
    }, [productId]);

    useEffect(() => {
        const { data: { subscription } } = supabase.auth.onAuthStateChange(
            async (event, session) => {
                setSession(session);
            }
        );

        return () => {
            subscription?.unsubscribe();
        };
    }, []);

    const createCheckoutSession = async (priceId) => {
        try {
            const requestBody = {
                user_email: session.user.email,
                user_id: session.user.id,
                price_id: priceId,
                productId: productId,
            };

            if (productData) {
                requestBody.priceText = productData.priceText;
            } else if (activeProductID) {
                requestBody.activate = "1";
                requestBody.productId = activeProductID;
            }

            const response = await fetch('/create-subscription-checkout-session', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });

            if (response.ok) {
                const data = await response.json();
                if (data.redirectUrl) {
                    window.location.href = data.redirectUrl;
                } else {
                    console.error('Failed to create checkout session');
                }
            } else {
                console.error('Failed to create checkout session');
            }
        } catch (error) {
            console.error('Error creating checkout session:', error);
        }
    };

    const handleBuyLater = () => {
        setactivateProduct(false);
        setActiveProductID(null);
        setShowPlans(false);
    };

    const handleCancel = async () => {
        const userID = session.user.id;
          try {
            await fetch(`/delete?id=${productId}&userID=${userID}`, {
              method: 'DELETE'
            });
            window.location.href = '/'; 
          } catch (error) {
            console.error('Error deleting product:', error);
          }
        };
    

    return (
        <div className={session ? 'marginM' : ''}>            
            {!session && 
                <NavBar />
            }

            <h3 className={session ? 'noMarginP' : 'noMarginP marginM'}>Trackers for Every Need</h3>
            {productId && productData ? 
                (
                    <p>Get one tracker for just 0.99€, or save money picking up extra trackers for everything else on your wishlist.</p>

                ) : (
                    <p>Buy individually or save more with 10 or 100 packs.</p>
                )
            }

            <div className="plans-grid marginB">
                <div className="plan-element">
                    <h3 className="noMarginP">1 Tracker</h3>
                    <p className="smallP noMarginP">Perfect for trying out our tracker</p>
                    <p className="price">0.99€</p>
                    <p className="savings">Test our service with minimal risk</p>
                    {session ? 
                        (
                            <button className="btnBasic" onClick={() => createCheckoutSession('price_1PPTVlH2oOAoVJ69m8vVT6iG')}>Buy 1 tracker</button>
                        ) : (
                            <button className="btnBasic" onClick={() => window.location.href='/signup'}>Start with 1 tracker</button>
                        )
                    }
                </div>
                <div className="plan-element">
                    <h3 className="noMarginP">Buy 10 Trackers</h3>
                    <p className="smallP noMarginP">Ideal for tracking multiple items</p>
                    <p className="price"><span className="savedPrice">9.9€</span> 7.99€</p>
                    <p className="savings">Save 2€ compared to buying individually</p>
                    {session ? 
                        (
                            <button className="btnBasic" onClick={() => createCheckoutSession('price_1PPTgWH2oOAoVJ69OnUjwgEg')}>Buy 10 trackers</button>
                        ) : (
                            <button className="btnBasic" onClick={() => window.location.href='/signup'}>Start with 1 tracker</button>
                        )
                    }
                </div>
                <div className="plan-element">
                    <h3 className="noMarginP">Buy 100 Trackers</h3>
                    <p className="smallP noMarginP">Best value for heavy users</p>
                    <p className="price"><span className="savedPrice">99.9€</span> 59.99€</p>
                    <p className="savings">Save 40€ compared to buying individually</p>
                    {session ? 
                        (
                            <button className="btnBasic" onClick={() => createCheckoutSession('price_1PPTi7H2oOAoVJ69GfK82lEu')}>Buy 100 trackers</button>
                        ) : (
                            <button className="btnBasic" onClick={() => window.location.href='/signup'}>Start with 1 tracker</button>
                        )
                    }
                </div>
                </div>
            {session && !productId && !productData ? 
                (
                    <button className='btnBasic fullWidth greyBtn' onClick={handleBuyLater}>Buy later</button>
                ) : (
                    session && <button className='btnBasic fullWidth greyBtn' onClick={handleCancel}>Cancel</button>
                )
            }

            {!session &&
                <div className='marginT'>
                    <FAQComponent />
                    <Footer />
                </div>
            }
        </div>    
    );
};

export default Plans;
